/**
 * File: Vdr.tsx
 * Description: This file contains the implementation of the Product component.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import Layout from '../../component/layout';

//Image imports
import blogvirtauldataroomimg from '../../assets/images/blog/blog-virtualdataroom.webp';
import blogvdr1 from '../../assets/images/blog-vdr-1.png'
import blogvdr3 from '../../assets/images/blog-vdr-2.png'
import prashantpic from '../../assets/images/prashant.jpeg';
import blogvdr2 from '../../assets/images/blog-vdr-3.png';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../../component/scrollAnimation';

const RoleOfVdrPostCovid: React.FC = () => {
    // Component implementation goes here
    return (
        <Layout>
            <div className="bg-white flex flex-col">
                <div className="flex items-stretch gap-1.5 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-0">
                        <div className="items-stretch flex justify-between gap-2 pr-2">
                            <div className="text-orange-400 text-sm font-medium leading-5">
                                <Link to='/'> Home</Link>
                            </div>
                            <div className="text-orange-400 text-sm leading-5">&gt;</div>
                        </div>
                        <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                            <Link to='/product'>  Blog </Link>
                        </div>
                    </div>
                    <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Role Of Virtual Dataroom Post Covid
                    </div>
                </div>
                <div className="self-center w-full max-w-[1597px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="text-blue-950 text-4xl mb-5 font-semibold self-stretch max-md:max-w-full">
                        Role Of Virtual Dataroom Post Covid
                    </div>
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[56%] max-md:w-full max-md:ml-0">
                        <img
                                    loading="lazy"
                                    src={blogvirtauldataroomimg}
                                    srcSet={`${blogvirtauldataroomimg} 1x, ${blogvirtauldataroomimg} 2x , ${blogvirtauldataroomimg} 4x, ${blogvirtauldataroomimg} 8x, ${blogvirtauldataroomimg} 12x, ${blogvirtauldataroomimg} 16x, ${blogvirtauldataroomimg} 20x`}
                                    alt='Role of Virtual Data Room Post Covid'
                                    className="aspect-[1.84] object-contain object-center w-full self-stretch overflow-hidden max-md:max-w-full"
                                />
                        </div>
                        <div className="flex flex-col items-stretch w-[40%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-zinc-500 text-justify text-lg self-stretch max-md:max-w-full">
                                In today’s scenario where the aftermath of a monstrous pandemic has reached almost every nook and corner, nothing has remained unvaried. One of the remarkable changes is duly evident in the way that we now work. It was merely two years ago that working for any company was synonymous with a monotonous routine of getting dressed, checking into the office, working and then checking out. Tables have turned now and the whole format of work life has been updated, whilst the previous now is often deemed to be obsolete. With the advent of remote work environments, operational costs have reduced, happiness indices have gone up, ease of access has settled in, and life has become conclusively easier. 
                                <br /><br />
                                Diving into the grey area beneath the green grass, the stumbling block has been data security. Now that everyone is working from various locations, huge amounts of data is to be worked with, thus putting its security at risk. While one’s comfort and efficiency may spur as a result of working from their home, the undeniable comfort comes with the possibility of data leakage as well. In order to make these conditions emerge as the new normal, companies are in dire need to update their ways of working, their approaches towards data’s security and its fundamentals as well.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollAnimation>
                <div className="self-center w-full max-w-[1597px] max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[40%] max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col mt-8 items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-zinc-500 text-justify text-lg self-stretch mt-2 max-md:max-w-full">
                                Nowadays that employees are more scattered , businesses must modify their data security strategy. Although data security is a concern for managers, with 81 percent reporting their firm has provided staff with training on the topic, just 61 percent of employees concur, indicating a communication barrier.
                                <br /><br />In the need of keeping data security at a high rank in the priority cycle of every company, a new and upcoming technology is now to the rescue. Virtual data rooms are here to put an end to the risk of data leakage. A Virtual data room is synonymous to the ideology of a deal room. It It is a digital storeroom which is used to securely save and distribute documents over the internet. 
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[56%] ml-10 max-md:w-full max-md:ml-0">
                        <img
                                    loading="lazy"
                                    src={blogvdr1}
                                    srcSet={`${blogvdr1} 1x, ${blogvdr1} 2x , ${blogvdr1} 4x, ${blogvdr1} 8x, ${blogvdr1} 12x, ${blogvdr1} 16x, ${blogvdr1} 20x`}
                                    alt='Role of Virtual Data Room Post Covid'
                                    className="aspect-[1.84] object-contain object-center w-full self-stretch overflow-hidden max-md:max-w-full"
                                />
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
                <ScrollAnimation>
                <div className="self-center w-full max-w-[1597px] max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[56%] max-md:w-full max-md:ml-0">
                        <img
                                    loading="lazy"
                                    src={blogvdr2}
                                    srcSet={`${blogvdr2} 1x, ${blogvdr2} 2x , ${blogvdr2} 4x, ${blogvdr2} 8x, ${blogvdr2} 12x, ${blogvdr2} 16x, ${blogvdr2} 20x`}
                                    alt='Role of Virtual Data Room Post Covid'
                                    className="aspect-[1.84] object-contain object-center w-full self-stretch overflow-hidden max-md:max-w-full"
                                />
                        </div>
                        <div className="flex flex-col items-stretch w-[40%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-zinc-500 text-justify text-lg self-stretch mt-8 max-md:max-w-full">
                                VDRs put an end to a recurring and one of the most highly common problems, which is the increasing risk to companies’ data. And, with the number of people who are to be given access to the data simultaneously and work upon it together, VDRs come in handy because of the humongous benefits that VDRs offer, ease of access being one of the top. Thus, the process of sharing of data becomes even more secure with the omission of borderline precarious actions like cloning, printing and relaying, all thanks to VDRs.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
                <ScrollAnimation>
                <div className="self-center w-full max-w-[1597px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[40%] max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-zinc-500 text-justify text-lg self-stretch max-md:max-w-full">
                                In the need of keeping data security at a high rank in the priority cycle of every company, a new and upcoming technology is now to the rescue. Virtual data rooms are here to put an end to the risk of data leakage. A Virtual data room is synonymous to the ideology of a deal room. It It is a digital storeroom which is used to securely save and distribute documents over the internet. 
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[56%] ml-10 max-md:w-full max-md:ml-0">
                        <img
                                    loading="lazy"
                                    src={blogvdr3}
                                    srcSet={`${blogvdr3} 1x, ${blogvdr3} 2x , ${blogvdr3} 4x, ${blogvdr3} 8x, ${blogvdr3} 12x, ${blogvdr3} 16x, ${blogvdr3} 20x`}
                                    alt='Role of Virtual Data Room Post Covid'
                                    className="aspect-[1.84] object-contain object-center w-full self-stretch overflow-hidden max-md:max-w-full"
                                />
                        </div>
                    </div>
                    <div className="items-stretch flex gap-3 mt-14 mb-10 max-md:mt-10">
                        <img
                        loading="lazy"
                        src={prashantpic}
                        srcSet={`${prashantpic} 1x, ${prashantpic} 2x , ${prashantpic} 4x, ${prashantpic} 8x, ${prashantpic} 12x, ${prashantpic} 16x, ${prashantpic} 20x`}
                        alt='Prashant Pandey'
                        className="aspect-square object-contain object-center w-20 justify-center items-center overflow-hidden shrink-0 max-w-full"
                        />
                        <div className="items-stretch flex grow basis-[0%] pt-10 flex-col">
                            <div className="text-gray-900 text-lg font-medium leading-5 whitespace-nowrap">
                                Prashant Pandey
                            </div>
                            <div className="text-gray-500 text-lg leading-5 pt-2 whitespace-nowrap">
                                22 May 2022
                            </div>
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
            </div>
        </Layout>
    );
};

export default RoleOfVdrPostCovid;
