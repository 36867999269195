/**
 * File: carousel.tsx
 * Description: This file contains the Carousel component for the Capitall application.
 * Author: Capitall
 * Date: 2023-12-30
 * Company: Capitall.Io
 */

// Carousel.tsx
import React, { useState, useEffect } from 'react';
import hpcarouselimg1 from '../assets/images/homepage/hp-img-carousel1.png'
//import './Carousel.css'; // Import your carousel styles

interface CarouselProps {
    images: string[];
    autoPlay?: boolean;
    showNextPrevButtons?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
    images,
    autoPlay = true,
    showNextPrevButtons = true,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const nextSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (autoPlay) {
            // Auto-play every 3 seconds
            intervalId = setInterval(() => {
                nextSlide();
            }, 5000);
        }

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [activeIndex, autoPlay]);

    return (
        <>
            <div className="shadow-sm bg-white flex flex-col items-stretch w-full rounded-xl max-md:max-w-full max-md:mt-10 m-display-none">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Slide ${index + 1}`}
                        className={` max-w-[55%] max-h-[70%] absolute w-full h-full ${index === activeIndex ? 'opacity-100' : 'opacity-0'
                            } transition-opacity duration-500`}
                    />
                ))}
                {showNextPrevButtons && images.length > 1 && (
                    <div className="carousel-controls absolute top-1/2 transform -translate-y-1/2 flex justify-between w-full">
                        <button
                            onClick={prevSlide}
                            className="bg-gray-800 text-white p-2 rounded-full opacity-50 hover:opacity-100"
                        >
                            &lt;
                        </button>
                        <button
                            onClick={nextSlide}
                            className="bg-gray-800 text-white p-2 rounded-full opacity-50 hover:opacity-100"
                        >
                            &gt;
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default Carousel;

