/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: footer.tsx
 * Description: This file is the blog page component.
 */

import React from 'react';
import Layout from '../component/layout';

import vdricon from '../assets/images/vdr.jpg';
import financial from '../assets/images/ifc.png';
import ofcauto from '../assets/images/oatool.jpg';
import compliance from '../assets/images/compliance.jpg';
import asticon from '../assets/images/FixAssetModule.jpg';
import vendoricon from '../assets/images/vm.jpg';
import { Link } from 'react-router-dom';

const Product: React.FC = () => {
    // Add your component logic here

    return (
        <Layout>
            <div className="bg-white flex flex-col">

                <div className="items-stretch flex gap-0 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-2 pr-2">
                        <div className="text-orange-400 text-sm font-medium leading-5">
                            Home
                        </div>
                        <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    </div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Products
                    </div>
                </div>
                <div className="justify-center self-center w-full max-w-[1527px] mt-1.5 px-5 max-md:max-w-full">
                        <div className='self-center w-full max-w-[1527px] mt-20 mb-14 max-md:max-w-full max-md:my-10'>
                    <div className="text-blue-950 text-2xl text-left font-bold leading-10 max-md:max-w-full">
                        Overview of our tools & services
                    </div>
                    
                    <div className="self-stretch w-full px-5 max-md:max-w-full max-md:mt-10">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[32%] max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                            <img
                                loading="lazy"
                                src={vdricon}
                                alt='vdr'
                                className="aspect-[0.95] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                            <Link to='/data-room'>Virtual Data Room</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7">
                            A virtual data room (VDR) is a secure online repository for storing and sharing confidential documents.
                            </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[35%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch flex grow flex-col max-md:mt-6">
                            <img
                                loading="lazy"
                                src={financial}
                                alt='financial'
                                className="aspect-[0.95] object-contain object-center w-[290px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                            <Link to="/ifc">Financial Control Tool</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7">
                            Our Internal Financial Control Tool is easy to use and scalable, making it the perfect solution.
                            </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch flex grow flex-col mt-1 max-md:mt-7">
                            <img
                                loading="lazy"
                                src={ofcauto}
                                alt='ofc-auto'
                                className="aspect-[1.05] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                            <Link to="/office-automation">Office Automation</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7">
                            Office automation tools are software applications that can automate a variety of tasks. 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    </div>
                    <div className="relative self-center w-full max-w-[1527px] mt-6 mb-20 max-md:max-w-full max-md:my-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                            <img
                            loading="lazy"
                            src={compliance}
                            alt='compliance'
                            className="aspect-[0.93] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                            <Link to="/compliance-tool">Compliance Tool</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7">
                            Compliance management software is a tool that helps organizations comply with internal policies.
                            </div>
                        </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[36%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch relative flex grow flex-col max-md:max-w-full max-md:mt-6">
                            <img
                            loading="lazy"
                            src={asticon}
                            alt='ast-mgmt'
                            className="aspect-[1.05] object-contain object-center w-[330px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-1">
                            <Link to="/fixed-asset">Asset Management</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7 max-md:max-w-full mt-1">
                            Our tool is a comprehensive solution for tracking all of your fixed assets. It allows you to easily create and manage asset.
                            </div>
                        </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[31%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                            <img
                            loading="lazy"
                            src={vendoricon}
                            alt='vendor'
                            className="aspect-[0.95] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-1">
                            <Link to="/vendor-management">Vendor Management</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7 mt-1">
                            Our vendor management tool provides a central repository for all of your vendor information.
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                                <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                &nbsp;
                                </div>
                                <div className="text-zinc-600 text-center text-base leading-7">
                                &nbsp;
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                                <img
                                loading="lazy"
                                src={compliance}
                                alt='compliance'
                                className="aspect-[0.93] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                                />
                                <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                <Link to="/business-intelligence">Business Intelligence Services</Link>
                                </div>
                                <div className="text-zinc-600 text-center text-base leading-7">
                                &nbsp;
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                                <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                &nbsp;
                                </div>
                                <div className="text-zinc-600 text-center text-base leading-7">
                                &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                

        </div>
        </Layout>
    );
};

export default Product;
