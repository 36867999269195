/**
 * File: Vdr.tsx
 * Description: This file contains the implementation of the Product component.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import Layout from '../component/layout';

//Image imports
import producttopimg from '../assets/images/product/product-top-img.webp';
import tickicon from '../assets/images/product/tick-icon.svg';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../component/scrollAnimation';

const Ifc: React.FC = () => {
    // Component implementation goes here
    return (
        <Layout>
            <div className="bg-white flex flex-col">
                <div className="flex items-stretch gap-1.5 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-0">
                        <div className="items-stretch flex justify-between gap-2 pr-2">
                            <div className="text-orange-400 text-sm font-medium leading-5">
                                <Link to='/'> Home</Link>
                            </div>
                            <div className="text-orange-400 text-sm leading-5">&gt;</div>
                        </div>
                        <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                            <Link to='/product'>  Products </Link>
                        </div>
                    </div>
                    <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Internal Financial Control Tool 
                    </div>
                </div>
                <div className="self-center w-full max-w-[1597px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[56%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={producttopimg}
                                srcSet={`${producttopimg} 1x, ${producttopimg} 2x , ${producttopimg} 4x, ${producttopimg} 8x, ${producttopimg} 12x, ${producttopimg} 16x, ${producttopimg} 20x`}
                                alt='product-top-img'
                                className="aspect-[1.69] object-contain object-center w-full overflow-hidden max-w-[707px]"
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col mt-8 items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-black text-3xl font-semibold self-stretch max-md:max-w-full">
                                    Internal Financial Control Tool
                                    <br />
                                    <br />
                                </div>
                                <div className="text-zinc-500 text-justify text-lg self-stretch mt-8 max-md:max-w-full">
                                At CapItAll we believe that strong internal financial controls are essential for any business that wants to be successful. 
                                That's why we developed our Internal Financial Control Tool, a powerful software solution that helps businesses of all sizes to:<br /><br />
                                <ul className='list-disc pl-5'>
                                    <li>Identify and mitigate financial risks</li>
                                    <li>Ensure compliance with regulations</li>
                                    <li>Improve financial reporting accuracy</li>
                                    <li>Increase efficiency and productivity</li>
                                </ul><br /><br />
                                Our Internal Financial Control Tool is easy to use and scalable, making it the perfect solution for businesses of all sizes. 
                                It is also fully customizable to meet the specific needs of your organization. 
                                </div>
                                {/*<img
                                    loading="lazy"
                                    src={chaticon}
                                    alt='chat-icon'
                                    className="aspect-square object-contain object-center w-[101px] shadow-lg overflow-hidden max-w-full mr-10 mt-24 max-md:mr-2.5 max-md:mt-10"
                                />*/}
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollAnimation>
                <div className="self-center flex w-full max-w-[1527px] flex-col mt-24 px-5 max-md:max-w-full max-md:mt-10">
                    <div className="text-blue-950 text-3xl font-semibold self-start max-md:max-w-full m-auto">
                    Here Are Some Of The Key Features of Our Internal Financial Control Tool
                    </div>
                    <div className="text-zinc-500 text-center text-xl mt-4 self-start max-md:max-w-full m-auto">
                    If you're looking for a powerful and easy-to-use internal financial control tool, then you need to check out our Internal Financial Control Tool. Contact us today to learn more or to schedule a demo. 
                    </div>
                    <div className="self-stretch mt-24 max-md:max-w-full max-md:mt-10 mb-20">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-5 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Risk assessment:{" "}
                                        <span className=" leading-6">
                                        Our tool helps you to identify and assess the financial risks that your business faces. This includes both internal and external risks. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Compliance monitoring:{" "}
                                        <span className=" leading-6">
                                        Our tool helps you to ensure that your business is in compliance with all applicable regulations. This includes Sarbanes-Oxley, SOX, and GDPR. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto mt-1">
                                        Financial reporting:{" "}
                                        <span className=" leading-6">
                                        Our tool helps you to improve the accuracy and timeliness of your financial reporting. This can help you to attract investors and lenders. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[1007px] max-w-full mb-20 self-start max-md:mt-10 m-auto">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-7 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-7 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Reporting and analytics:{" "}
                                        <span className=" leading-6">
                                        Our tool provides you with comprehensive reporting and analytics capabilities, so you can track your financial performance and identify areas for improvement. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-7 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto mt-1.5">
                                        Process automation:{" "}
                                        <span className=" leading-6">
                                        Our tool can automate many of your financial processes, which can free up your time and resources for other tasks. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
                <div className="bg-blue-950 self-stretch flex w-full flex-col items-center mt-2 px-20 py-12 max-md:max-w-full max-md:px-5">
                    <div className="text-white text-center text-4xl font-semibold leading-5 mt-6 max-md:max-w-full m-text-lh">
                        Overview about Internal Financial Control Tool
                    </div>
                    <Link to="/contact">
                    <div className="text-white text-center text-base font-semibold leading-5 whitespace-nowrap justify-center items-center rounded bg-orange-400 w-[237px] max-w-full mt-11 mb-1.5 px-16 py-4 max-md:mt-10 max-md:px-5">
                        Get Quotation
                    </div>
                    </Link>
                </div>
            </div>
        </Layout>
    );
};

export default Ifc;
