/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: team.tsx
 * Description: This file contains the Team page component.
 */

import React from 'react';
import Layout from '../component/layout';
import { Link } from 'react-router-dom';

//Image import
import teamtopbanner from '../assets/images/team/team-top-banner.webp';
import prashantpic from '../assets/images/prashant.jpeg';
import anujsomanipic from '../assets/images/team/anujsomani.webp';
import triptishinghalpic from '../assets/images/team/tripti.png';
import varunpic from '../assets/images/team/sharad.png';
import mohitpic from '../assets/images/team/mohit-arora.jpg'
import simmipic from '../assets/images/team/simmi-puri.jpg';
import sukhpic from '../assets/images/team/sukh-bahadur.jpg';
import sharadpic from '../assets/images/team/varun.png';
import prakharpic from '../assets/images/team/prakhar.png';
import sachinpic from '../assets/images/team/sachin.png';
import arihantpic from '../assets/images/team/arihant-jain.jpg';
import shasankpic from '../assets/images/team/SS.png';
import chaticon from '../assets/images/homepage/chat-icon.svg';
import lefarrow from '../assets/brand/left-arrow.svg';
import rightarrow from '../assets/brand/right-arrow.svg';
import ImageListCarousel from '../component/imagelistcarousel';

interface ImageInfo1 {
    name: string;
    designation: string;
    imageUrl: string;
    bigImageUrl: string;
    description: string;
}

const Team: React.FC = () => {
    // Your component logic here
    //const allImages = [{triptishinghalpic, varunpic, sharadpic, prakharpic, sachinpic, shasankpic}];
    const allImages = [
        {
            name: 'Anuj Somani', designation: '', imageUrl: anujsomanipic, bigImageUrl: anujsomanipic,
            description: 'Anuj\'s career is characterized by a data-centric approach.' +
                        ' and he specializes in leveraging modern technology and intelligent tools to optimize financial processes,' +
                        ' driving informed decision-making and enhancing overall business performance.' +
                        ' His expertise lies in integrating financial acumen with technology and automation.' +
                        ' Anuj is a Chartered Accountant,' +
                        ' holds a Post Graduate degree in Business Laws from the esteemed National Law School Bangalore,' +
                        ' and has pursued General Management at Harvard Business School.'
        },
        {
            name: 'Tripti Shinghal', designation: '', imageUrl: triptishinghalpic, bigImageUrl: triptishinghalpic,
            description: 'Having held pivotal roles in esteemed organizations like PwC and Grant Thornton,' +
                        ' Tripti has earned acclaim in both profit and non-profit sectors.' +
                        ' She is a trusted advisor for businesses of all sizes,' +
                        ' recognized for her deep-rooted understanding of financial management.' +
                        ' Beyond her professional achievements, she is a TEDx speaker' +
                        ' and recipient of prestigious awards,' +
                        ' including the Economic Times Inspiring Leaders Award' +
                        ' and Times 40 under 40 Award.'
        },
        {
            name: 'Sharad Maheshwari', designation: '', imageUrl: sharadpic, bigImageUrl: sharadpic,
            description: 'Sharad is a qualified Chartered Accountant, Cost Accountant and Company Secretary and CPA (Australia),' +
                        ' Sharad has been in the Corporate world for over Twenty-Five Years.' +
                        ' His has served in leadership roles in world class Oil & Gas with companies like Schlumberger, Baker Hughes,' +
                        ' Bumi Armada across India, Middle East, South East Asia and Nigeria.' +
                        ' He has also worked in Corporate Finance & Risk Department of Dalmia and Lalit Suri group (Bharat Hotels).'
        },
        {
            name: 'Shashank Singh', designation: '', imageUrl: shasankpic, bigImageUrl: shasankpic,
            description: 'Shashank Singh is an accomplished engineering leader' +
                        ' with a demonstrated history of driving innovation,' +
                        ' successfully managing complex projects,' +
                        ' and fostering team growth across diverse industries.' +
                        ' His experience spans from architecting microservices solutions for Fortune 500 clients' +
                        ' to co-founding and scaling online marketplaces for blue collar jobs,' +
                        ' consistently delivering exceptional results through a combination of technical expertise and strategic vision.'
        },
        {
            name: 'Prakhar Kalani', designation: '', imageUrl: prakharpic, bigImageUrl: prakharpic,
            description: ' Prakhar Kalani is a data analyst with rich'
            + ' experience of Python, SQL and MVC'
            + ' Framework along with the sound'
            + ' knowledge of finance, Companies Act, tax'
            + ' laws and Indian GAAP. He has the'
            + ' experience of managing large data sets'
            + ' for telecom company, Asset management'
            + ' Company, Commodity exchange for'
            + ' various assignments.'
        },
        {
            name: 'Sachin Shukla', designation: '', imageUrl: sachinpic, bigImageUrl: sachinpic,
            description: 'Sachin Shukla, an alumnus of IIT'
            + ' Kharagpur and Harvard Business School, is'
            + ' our Strategic Advisor. With a rich'
            + ' background in technology, executive'
            + ' leadership, entrepreneurship, and'
            + ' mentorship, Sachin brings extensive expertise'
            + ' to our team. His role is pivotal in'
            + ' guiding our growth and innovation efforts.'
        },
        {
            name: 'Mohit Arorar', designation: '', imageUrl: mohitpic, bigImageUrl: mohitpic,
            description: 'With a career journey spanning the'
            + ' prestigious corridors of the Presidents'
            + ' House to fortifying the digital rails of IRCTC'
            + ' and safeguarding the renowned'
            + ' Maharajas Express, He have amassed a wealth'
            + ' of experience in IT networking and security.'
            + ' Serving as an IT Manager at KG Somani'
            + ' & Co LLP further refined my expertise before'
            + ' I took the helm as managing the Security at'
            + ' CapItAll.'
        },
        {
            name: 'Simmi Puri', designation: '', imageUrl: simmipic, bigImageUrl: simmipic,
            description: 'Edtech founder and social impact curator'
            + ' with global experience across the'
            + ' education and social development'
            + ' sectors. Career span senior leadership and'
            + ' entrepreneurial roles in product'
            + ' innovation, digital transformation, and'
            + ' corporate strategy. Raised funds and built'
            + ' innovative platforms at CISO, Adviath'
            + ' Foundation (Infosys), Sattva Advisory'
            + ' Womennovator, and GenLeap'
        },
        {
            name: 'Varun Sharma', designation: '', imageUrl: varunpic, bigImageUrl: varunpic,
            description: 'Varun Sharma is a chartered accountant'
            + ' and a commerce graduate. Varun has'
            + ' over 13 years of experience in audits,'
            + ' restructuring and corporate advisory. He'
            + ' has been extensively doing valuations and'
            + ' has supervised over 100+ valuations'
            + ' engagements of mid sized and large'
            + ' corporates including domestic and'
            + ' international clients.'
        },
        {
            name: 'Prashant Pandey', designation: '', imageUrl: prashantpic, bigImageUrl: prashantpic,
            description: 'Prashant Pandey is a Product Manager at CapItAll. In his'
            + ' role, he oversee both teams and projects,'
            + ' leveraging over 9 years of experience in'
            + ' development and team management.'
            + ' Throughout my career, he have successfully'
            + ' managed more than 50 projects,'
            + ' delivering outstanding results.'
        },
        {
            name: 'Arihant Jain', designation: '', imageUrl: arihantpic, bigImageUrl: arihantpic,
            description: 'Arihant is a Chartered Accountant with a'
            + ' pioneering spirit and passion for'
            + ' technology. With a keen understanding of'
            + ' financial and regulatory landscapes, he is'
            + ' at the forefront of revolutionizing how'
            + ' organizations manage risk and'
            + ' compliance in the digital age. In CapItAll,'
            + ' he is leading the continuous development'
            + ' of cutting-edge Governance, Risk, and'
            + ' Compliance (GRC) solutions.'
        },
        {
            name: 'Sukh Bahadur Lama', designation: '', imageUrl: sukhpic, bigImageUrl: sukhpic,
            description: 'I am a Full Stack Developer at CapItAll,'
            + ' where I specialize in developing projects. I'
            + ' thrive on new challenges and am'
            + ' passionate about learning new languages'
            + ' and technologies. With 5 years of'
            + ' experience as a Full Stack Developer.'
        }
    ];
    const [selectedUser, setSelectedUser] = React.useState(allImages[0]);
    const handleUserClick: (user: ImageInfo1) => void = (user) => {
        //console.log(user);
        setSelectedUser(user);
    };
    return (
        <Layout>
            <div className="bg-white flex flex-col">

                <div className="items-stretch flex gap-0 ml-20 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-2 pr-2">
                        <div className="text-orange-400 text-sm font-medium leading-5">
                            <Link to='/'> Home </Link>
                        </div>
                        <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    </div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Our Team
                    </div>
                </div>


                <div className="flex-col text-white text-5xl font-semibold leading-[66px] relative whitespace-nowrap overflow-hidden self-stretch min-h-[673px] w-full justify-center items-center mt-7 pt-72 pb-72 px-16 max-md:text-4xl max-md:max-w-full max-md:px-5 max-md:py-10">
                    <img
                        loading="lazy"
                        src={teamtopbanner}
                        srcSet={`${teamtopbanner} 1x, ${teamtopbanner} 2x , ${teamtopbanner} 4x, ${teamtopbanner} 8x, ${teamtopbanner} 12x, ${teamtopbanner} 16x, ${teamtopbanner} 20x`}
                        alt='top banner'
                        className="absolute h-full w-full object-cover object-center inset-0"
                    />
                    Meet Our Team
                </div>
                <div className="self-center w-full max-w-[1520px] mt-32 max-md:max-w-full max-md:mt-10 ">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[55%] max-md:w-full max-md:ml-0">
                            <div className="flex flex-col items-stretch px-5 max-md:max-w-full max-md:mt-10">
                                <div className="text-neutral-900 text-5xl font-semibold max-md:max-w-full max-md:text-4xl">
                                    {selectedUser.name}
                                </div>
                                <div className="text-zinc-500 text-4xl font-medium mt-5 max-md:max-w-full">
                                    {selectedUser.designation}
                                </div>
                                <div className="text-black text-justify text-xl mt-16 max-md:max-w-full max-md:mt-10">
                                    {selectedUser.description}
                                </div>
                            </div>
                        </div>{" "}
                        <div className="flex flex-col items-stretch w-[45%] -mt-40 ml-5 max-md:w-full max-md:ml-0 transform scale-75">
                            <div className="bg-rose-100 flex flex-col justify-center items-stretch w-full mt-9 px-10 rounded-[50%] max-md:max-w-full max-md:mt-10 max-md:px-5">
                                <div className="flex-col overflow-hidden relative z-[1] flex min-h-[809px] mt-0 -mb-8 pl-16 pb-12 items-end max-md:max-w-full max-md:mb-2.5 max-md:pl-5">
                                    <img
                                        loading="lazy"
                                        src={selectedUser.bigImageUrl}
                                        srcSet={`${selectedUser.bigImageUrl} 1x, ${selectedUser.bigImageUrl} 2x , ${selectedUser.bigImageUrl} 4x, ${selectedUser.bigImageUrl} 8x, ${selectedUser.bigImageUrl} 12x, ${selectedUser.bigImageUrl} 16x, ${selectedUser.bigImageUrl} 20x`}
                                        alt='anuj somani'
                                        className="absolute h-full w-full object-cover object-center inset-0"
                                    />{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{" "}
                <div className="border shadow-lg backdrop-blur-[20px] self-stretch flex items-center justify-between gap-5 -mt-20 pl-8 pr-12 py-12 border-solid border-orange-400 max-md:max-w-full max-md:flex-wrap max-md:px-5">
                    <ImageListCarousel allImages={allImages} imagesPerPage={6} onClick={handleUserClick} />
                </div>{" "}
            </div>
        </Layout>
    );
};

export default Team;