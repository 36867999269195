/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: footer.tsx
 * Description: This file is the blog page component.
 */

import React from 'react';
import Layout from '../component/layout';
import { Link } from 'react-router-dom';

const TermsPage: React.FC = () => {
    // Add your component logic here

    return (
        <Layout>
            <div className="bg-white flex flex-col">

                <div className="items-stretch flex gap-0 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-2 pr-2">
                        <div className="text-orange-400 text-sm font-medium leading-5">
                            Home
                        </div>
                        <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    </div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Terms & Conditions
                    </div>
                </div>
                <div className="self-center w-full max-w-[1200px] max-md:max-w-full mb-10 max-md:mt-10">
                    <div className="text-zinc-500 text-left text-xl mt-14 self-start max-md:max-w-full leading-8">
                        These Terms & Conditions ("Terms") govern your access to and use of Capitall's website (the "Site") and services (the "Services"). By accessing or using the Site or Services, you agree to be bound by these Terms.
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Account Creation & Use
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        <ul className='mt-5'>
                            <li className='list-disc ml-5'>To access certain Services, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account.</li>
                            <li className='list-disc ml-5'>You agree to provide accurate, current, and complete information when creating or updating your account.</li>
                            <li className='list-disc ml-5'>You may not use the Services for any illegal or unauthorized purpose. You agree to comply with all applicable laws and regulations.</li>
                        </ul>
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Subscriptions & Payments
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        <ul className='mt-5'>
                            <li className='list-disc ml-5'><b>Subscription Plans:</b> We offer various subscription plans for the Services, including monthly, yearly, and one-time payment options. Details of each plan and its associated fees are outlined on the Site.</li>
                            <li className='list-disc ml-5'><b>Payment Terms:</b> Payment for subscriptions is due in advance and will be billed automatically to the payment method you provide.</li>
                            <li className='list-disc ml-5'><b>Cancellation:</b> You may cancel your subscription at any time. Cancellation will take effect at the end of your current billing cycle.</li>
                            <li className='list-disc ml-5'><b>Refunds:</b> Refunds are generally not provided for subscription fees. However, we may consider refund requests on a case-by-case basis.</li>
                        </ul>
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Intellectual Property
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                         <ul className='mt-5'>
                            <li className='list-disc ml-5'><b>Ownership: </b>The Services and their original content, features, and functionality are owned by [Your Company Name] and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</li>
                            <li className='list-disc ml-5'><b>Limited License: </b>We grant you a limited, non-exclusive, non-transferable license to access and use the Services for your personal or internal business purposes. </li>
                        </ul>
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Limitation of Liability Disclaimer of Warrant:
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        Capitall shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, arising out of or in connection with your use of the Services.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Termination
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        <ul className='mt-5'>
                            <li className='list-disc ml-5'>We may terminate or suspend your access to the Services at any time, without prior notice or liability, for any reason whatsoever.</li>
                            <li className='list-disc ml-5'>Upon termination, your right to use the Services will immediately cease.</li>
                        </ul>
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Governing Law
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Changes to Terms
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Contact Us
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        If you have any questions about these terms, please contact us at support@capitall.io.
                    </div>

                </div>

            </div>
        </Layout>
    );
};

export default TermsPage;
