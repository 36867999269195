/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: footer.tsx
 * Description: This file is the blog page component.
 */

import React from 'react';
import Layout from '../component/layout';
import { Link } from 'react-router-dom';

const PrivacyPage: React.FC = () => {
    // Add your component logic here

    return (
        <Layout>
            <div className="bg-white flex flex-col">

                <div className="items-stretch flex gap-0 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-2 pr-2">
                        <div className="text-orange-400 text-sm font-medium leading-5">
                            Home
                        </div>
                        <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    </div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Privacy Policy
                    </div>
                </div>
                <div className="self-center w-full max-w-[1200px] max-md:max-w-full mb-10 max-md:mt-10">
                    <div className="text-zinc-500 text-left text-xl mt-14 self-start max-md:max-w-full leading-8">
                        We are committed to protecting the privacy of our users. This Privacy Policy describes how we collect, use, and disclose personal information.
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Information
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 ml-10 font-semibold self-stretch max-md:max-w-full">
                        Collection
                    </div>
                    <div className="text-zinc-500 text-left text-xl ml-10 self-start max-md:max-w-full leading-8">
                        We collect personal information when you visit our website, sign up for our services, or contact us. Our servers are located with Cloud Web Service providers and secure. Therefore, if you choose to provide us with personal information, you are consenting to the transfer and storage of that information on our Cloud servers. We collect and store the following personal information:
                    </div>
                    <div className="text-zinc-500 text-left text-xl ml-10 self-start max-md:max-w-full leading-8">
                        <ul className='mt-5'>
                            <li className='list-disc ml-10'>name, email address, phone number, company name, job title, and (depending on the service used) sometimes financial information.</li>
                            <li className='list-disc ml-10'>computer sign-on data, statistics on page views, traffic to and from our products.</li>
                            <li className='list-disc ml-10'>Other information, including users IP address and standard web log information.</li>
                        </ul>
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 ml-10 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Use
                    </div>
                    <div className="text-zinc-500 text-left text-xl ml-10 self-start max-md:max-w-full">
                        We use users' personal information to:
                    </div>
                    <div className="text-zinc-500 text-left text-xl ml-10 self-start max-md:max-w-full leading-8">
                        <ul className='mt-5'>
                            <li className='list-disc ml-10'>provide our services</li>
                            <li className='list-disc ml-10'>resolve disputes, collect fees, and troubleshoot problems</li>
                            <li className='list-disc ml-10'>encourage safe usage and enforce our policies</li>
                            <li className='list-disc ml-10'>customize users' experience, measure interest in our services, and inform users about services and updates</li>
                            <li className='list-disc ml-10'>communicate marketing and promotional offers to you</li>
                            <li className='list-disc ml-10'>do other things for users as described when we collect the information.</li>
                        </ul>
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        How We Share Your Information
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        We may share your information with third-party service providers who help us operate our website, provide our services, or send you marketing and promotional materials. We may also share your information with third-party advertisers, social media companies, and other service providers.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Your Choices
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        You can choose to opt out of receiving marketing and promotional materials from us by following the unsubscribe instructions in any email we send you. You can also choose to delete your account at any time.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Security
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        We take the security of your information seriously. We use a variety of security measures to protect your information, including encryption and access controls.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Children's Privacy
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Changes to This Privacy Policy
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Contact Us
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        If you have any questions about this Privacy Policy, please contact us at support@capitall.io.
                    </div>

                </div>

            </div>
        </Layout>
    );
};

export default PrivacyPage;
