/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const VuesaxBoldTickCircle = ({ color = "#292D32", className }: Props): JSX.Element => {
  return (
    <svg
      className={`vuesax-bold-tick-circle ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.0001 2.66663C8.65341 2.66663 2.66675 8.65329 2.66675 16C2.66675 23.3466 8.65341 29.3333 16.0001 29.3333C23.3467 29.3333 29.3334 23.3466 29.3334 16C29.3334 8.65329 23.3467 2.66663 16.0001 2.66663ZM22.3734 12.9333L14.8134 20.4933C14.6267 20.68 14.3734 20.7866 14.1067 20.7866C13.8401 20.7866 13.5867 20.68 13.4001 20.4933L9.62675 16.72C9.24008 16.3333 9.24008 15.6933 9.62675 15.3066C10.0134 14.92 10.6534 14.92 11.0401 15.3066L14.1067 18.3733L20.9601 11.52C21.3467 11.1333 21.9867 11.1333 22.3734 11.52C22.7601 11.9066 22.7601 12.5333 22.3734 12.9333Z"
        fill={color}
      />
    </svg>
  );
};

VuesaxBoldTickCircle.propTypes = {
  color: PropTypes.string,
};
