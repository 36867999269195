/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const VuesaxBoldArrowUp1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`vuesax-bold-arrow-up-1 ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M14.0002 2.33337C7.56016 2.33337 2.3335 7.56004 2.3335 14C2.3335 20.44 7.56016 25.6667 14.0002 25.6667C20.4402 25.6667 25.6668 20.44 25.6668 14C25.6668 7.56004 20.4402 2.33337 14.0002 2.33337ZM18.1185 14.035C17.9435 14.21 17.7218 14.2917 17.5002 14.2917C17.2785 14.2917 17.0568 14.21 16.8818 14.035L14.8752 12.0284V18.0834C14.8752 18.5617 14.4785 18.9584 14.0002 18.9584C13.5218 18.9584 13.1252 18.5617 13.1252 18.0834V12.0284L11.1185 14.035C10.7802 14.3734 10.2202 14.3734 9.88183 14.035C9.5435 13.6967 9.5435 13.1367 9.88183 12.7984L13.3818 9.29837C13.7202 8.96004 14.2802 8.96004 14.6185 9.29837L18.1185 12.7984C18.4568 13.1367 18.4568 13.6967 18.1185 14.035Z"
        fill="#292D32"
      />
    </svg>
  );
};
