/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: about.tsx
 * Description: This file contains the About page component.
 */

import React, { useState,useEffect,useRef } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../component/layout';
import commpliance from '../../assets/brand/compliance.png';
import playButton from '../../assets/brand/play.svg';
import arrowRight from '../../assets/brand/arrow-right.svg';
import arrow from '../../assets/brand/akar-icons_chevron-right.svg';
import frame from '../../assets/brand/Col.png';
import smallImage from '../../assets/brand/topImage.png';
import lock from '../../assets/brand/lock.svg';
import chart from '../../assets/brand/chart.svg';
import calendar from '../../assets/brand/calendar-tick.svg';
import grid from '../../assets/brand/grid-edit.svg';
import mac from '../../assets/brand/Macbook.png';
import styles from './DataRoomPage.module.css';
import dashboardVideo from '../../assets/videos/Dashboard.mkv';
import interfaceVideo from '../../assets/videos/Interface.mkv';
import notificationVideo  from '../../assets/videos/Notification & Reminders.mkv';

// import editFeature from '../../assets/brand/Editfeature';
interface FaqItem {
    id: number;
    question: string;
    answer: string;
  }

const Compliance: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    window.addEventListener('resize', () => {
        setIsMobile(window.innerWidth <= 768);
    });
}, []);

const [activeVideo, setActiveVideo] = useState<string | null>(dashboardVideo);
const videoRef = useRef<HTMLVideoElement>(null);


    // Your component logic here
    interface QuestionAnswerPair {
        id: number;
        question: string;
        answer: string;
      }
      
        const [hoveredQuestionIndex, setHoveredQuestionIndex] = useState(-1);
      
        const questions: QuestionAnswerPair[] = [
          {id: 1,
             question: "Why should I choose CapitALL Compliance for my compliance management?",
             answer: " CapitALL Compliance offers streamlined, efficient, and secure compliance tracking. With automated workflows, real-time updates, and comprehensive reporting, our tool ensures you stay on top of all regulatory requirements." },
          { id:2,
            question: "How can I track tasks and compliance status using CapitALL Compliance?  ", 
            answer: " CapitALL Compliance provides interactive dashboards with real-time updates on all tasks. Easily access the 'Tasks' section to view your task list, receive real-time status indicators, and update task statuses to maintain overall compliance"},
          { id:3,
            question: 'How does compliance tracking with CapitALL save time and support business growth? ',
             answer: " CapitALL Compliance saves you time through automated tracking and reporting. Gain an eagle-eye view of your compliance status, identify bottlenecks, and focus on growing your business with efficient compliance management. " },
          { id:4,
            question: ' How can I manage permissions and restrictions in CapitALL Compliance? ', 
            answer:" Managing permissions and restrictions is straightforward with CapitALL Compliance. Assign roles, set access levels, and ensure data security with granular permission settings. Easily control who can access sensitive information and manage user permissions."},
          {id:5,
            question: ' Can CapitALL Compliance be customized for my specific industry needs? ', 
            answer:"Yes, CapitALL Compliance is highly customizable to fit your industry requirements. Tailor workflows, reports, and compliance checks to align perfectly with your field of work, ensuring flexibility and adaptability."},
        {id:6,
            question: " Is CapitALL Compliance easy to use? ", answer: "CapitALL Compliance is designed to be user-friendly with an intuitive interface. Navigate effortlessly through different sections, utilize help and support features, and complete compliance tasks quickly and easily."},
      
        ];
      
        const handleQuestionHover = (index: number) => {
          setHoveredQuestionIndex(index);
        };
      
        const [activeQuestionId, setActiveQuestionId] = useState<number | null>(1);

  const handleToggle = (id: number) => {
    setActiveQuestionId(id === activeQuestionId ? null : id);

    setActiveVideo(
      id === 1
        ? dashboardVideo
        : id === 2
          ? notificationVideo
          : id === 3
            ? interfaceVideo
              : null
    );
    
    // Ensure the video reloads/restarts
    if (videoRef.current) {
      videoRef.current.load(); // Reload video when activeVideo changes
    }
  };

  const data: FaqItem[] = [
    {
      id: 1,
      question: "Interactive Dashboards",
      answer: "Visualize compliance status with intuitive, interactive dashboards. Monitor tasks, deadlines, and overall compliance health at a glance.  ",
    },
    {
      id: 2,
      question: "Automated Notifications",
      answer: "Stay informed with automated notifications and reminders. Never miss a compliance deadline again ",
    },
    {
      id: 3,
      question: "User-Friendly Interface",
      answer: "Navigate through a clean, intuitive interface designed for ease of use. Quick onboarding and minimal training required ",
    },
   
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 4;
  const totalQuestions = data.length;


  const startIndex = (currentPage - 1) * questionsPerPage;
  const endIndex = Math.min(startIndex + questionsPerPage, totalQuestions);
  const slicedData = data.slice(startIndex, endIndex); 



  

    return (
        <Layout>
      <div className='ease-in duration-300 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8'>
        <Helmet>
          <title>Product Page - capitall.io</title>
          <meta name="description" content="Welcome to our website. Learn about our services and more." />
        </Helmet>
        <div className='justify-items-center'>
        <div className={`text-center mt-16 font-bold ${isMobile ? 'text-4xl' : 'text-5xl'}`}>Compliance</div>
        <p className={`mt-8 text-center ${isMobile ? 'mx-1' : 'mx-8'} ${isMobile ? 'text-base' : 'text-xl'}`}>Revolutionize your compliance management with a comprehensive, automated, and user-friendly solution.  </p>
        </div>
        <div className="flex flex-col justify-center items-center">
        <a className= " flex select-none mt-12 rounded-full bg-blue-950 py-2 px-4 font-sans text-2xl font-medium text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] " type="button" href='/contact'> Request A Demo<img src={arrowRight} className='py-2 pl-2'/></a>
        <div className={`mt-12 ${isMobile ? '' : 'p-16'}`}>
        <img
                        loading="lazy"
                        src={commpliance}
                        alt='Interface'
                        className="border-8 border-blue-950 rounded-lg "
                      />
        </div>
        <p className='mt-6 text-blue-950 font-bold flex'><img src={playButton} className='mr-4'/>Watch Demo</p>

        </div>
        <div className="flex flex-col justify-center items-center mt-[8rem]">
        <div className='text-center font-semibold text-3xl'>Benefits</div>
        <p className={`text-xl mt-4 mb-2 text-center  ${isMobile ? 'mx-4' : 'mx-20'}`}>Automate compliance tracking to save time and focus on growth. </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-5 mx-5 ">
            <div className='flex flex-col items-center mx-4 my-5 w-[274px] '>
                <div className='relative'>
                <div className='bg-orange-200 rounded-full w-16 h-16'><img src={calendar} className='pl-5 pt-5' /></div></div>
                <h4 className='text-xl font-semibold absoulte mt-4 text-center'>Automated Workflows & Efficient Tracking</h4>
                {/* <div className='flex'>
                <div className='w-4 h-4 mt-2 rounded-full bg-gray-700'></div>
                <p  className='text-l font-normal mt-2 text-center'> Direct access to data and analysis without delays. </p></div>
                <p  className='text-l font-normal mt-2 text-center'> Direct access to data and analysis without delays. </p> */}
            </div>
            <div className='flex flex-col items-center mx-4 my-5 w-[274px] '>
                <div className='bg-blue-100 rounded-full w-16 h-16 border-none'><img src={lock} className='pl-5 pt-5' /></div>
                <h4 className='text-xl font-semibold mt-4 text-center'>Granular Task Assignment & Secure Documentation</h4>
                {/* <p className='text-l font-normal mt-2 text-center'>Secure documentation with detailed access controls</p> */}
            </div>
            <div className='flex flex-col items-center mx-4 my-5 w-[274px] '>
                <div className='bg-blue-200 rounded-full w-16 h-16 border-none'><img src={chart} className='pl-5 pt-5' /></div>
                <h4 className='text-xl font-semibold mt-4 text-center'>Comprehensive Reporting & Real-Time Insights</h4>
                {/* <p className='text-l font-normal mt-2 text-center'>Quickly find and retrieve documents as needed. </p> */}
            </div>
            <div className='flex flex-col items-center mx-4 my-5 w-[274px] '>
                <div className='bg-slate-200 rounded-full w-16 h-16 border-none'><img src={grid} className='pl-5 pt-5' /></div>
                <h4 className='text-xl font-semibold mt-4 text-center'>Customizable to Your Industry Needs</h4>
                {/* <p className='text-l font-normal mt-2 text-center'>Focused consolidation of information for streamlined operations. </p> */}
            </div>
            
            {/* <div className='flex flex-col items-center mx-4 my-5'>
                <div className='bg-green-200 rounded-full w-16 h-16 border-none'></div>
                <h4 className='text-xl font-semibold mt-4'>Manageable Integration of All Data Sources</h4>
                <p className='text-l font-normal mt-2 w-[66%]'> Focused consolidation of information for streamlined operations. </p>
            </div> */}
</div>
            </div>
            <div className="flex flex-col justify-center items-center mt-20 ">
            <div className='text-center font-bold text-3xl'>Key Features</div>
            <div className={`flex mt-12 justify-between ${isMobile ? 'flex-col' : 'flex-row'} ` }>
            <div className={`${styles.videoContainer} relative w-full md:w-1/2`}>
              {/* Video container with responsive width */}
              {activeVideo && (
                <video
                  ref={videoRef}
                  controls
                  width="100%"
                  height="100%"
                  className="object-cover relative border-4 border-blue-950 rounded-lg"
                >
                  <source src={activeVideo} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
            <div className='flex-col w-[400px] h-[300px] mt-20 ml-20' >
            {slicedData.map((item) => (
        <div className="" key={item.id}>
            <span className={` absolute w-1 ease-in-out duration-300  ${activeQuestionId === item.id ? 'bg-orange-500' : 'bg-slate-300'} ${activeQuestionId === item.id ? ' h-36' : 'h-6'}`}></span>
          <button
            className={`flex text-xl mt-2 h-9 ml-4 ${activeQuestionId === item.id && item.id === 1 ? 'faq-accordion-question-button-active' : ''}`}
            onClick={() => handleToggle(item.id)}>
            {item.question}
          </button>
          {activeQuestionId === item.id && (
            <div className="text-md h-24 text-slate-900 mt-1 ml-11 w-[344px] mb-8">
              <p> {item.answer}</p>
            </div>
          )}
        </div>
      ))}
            </div>
            </div>
            </div>
            <div className={`flex mt-20 justify-center ${isMobile ? '' : 'mx-16'}  ${isMobile ? 'flex-col' : ''}`}>
            {/* <div className={`font-bold text-3xl ${isMobile ? 'mr-8' : ''} `}>Get Started</div> */}
            <div className={`space-x-2 flex ${isMobile ? 'mt-4' : ''}`}>
            <a className='select-none w-64 rounded-lg bg-blue-950 py-2 px-2 font-sans text-base font-medium text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85]' href='/contact'>Schedule a Demo</a>
            <a className='select-none w-64 rounded-lg  py-2 px-2 font-sans text-base font-medium shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] border-2 border-blue-950 ' href='/contact'>Connect with Us</a>
            </div>
            </div>
            <div className={`mt-20 mx-16 justify-between ${isMobile ? 'hidden' : 'block'}`}>
            <div className='font-bold text-4xl'>Frequently Asked Quetions</div>
            <div className='flex relative' >
            <div className='flex-col w-[640px] h-[460px] top-[144px] mt-24 abosulte border-slate-200 border-2'>
            {/* <div className='flex justify-between'></div> */}
            {questions.map((qna, index) => (
          <div
            key={index}
            className={`h-[76px] px-6 py-4 flex shadow hover:bg-gray-200 ${
              hoveredQuestionIndex === index ? 'bg-gray-300' : ''
            }`}
            onMouseEnter={() => handleQuestionHover(index)}
            onMouseLeave={() => setHoveredQuestionIndex(-1)}
          >
            <div className='rounded-full w-6 h-6 bg-indigo-300 mr-6 hover:bg-indigo-600'></div>
            <div className="w-[460px]">{qna.question}</div>
            <div><img src={arrow} className='w-6 h-6  mx-4 my-3'/></div>
            
            {/* <div className="text-gray-600">{qna.question.length > 30 ? qna.question.slice(0, 30) + '...' : qna.question}</div> */}
          </div>
        ))}
        
            {/* <div className='flex justify-between'>
            <div className='h-[72px] px-6 py-4 flex' >
                <div className='rounded-full w-6 h-6 bg-indigo-300 mr-6'></div>
                <p className='w-[460px]'>Can anyone access data room Can anyone access data room Can anyone access data room ? </p></div>
                <div><img src={arrow} className='w-6 h-6  mx-4 my-3'/></div>
            </div>  */}
            </div>
            <div className='flex-col w-[648px] h-[472px] rounded-2xl bg-slate-50 mt-16'>
        {questions.map((qna, index) => (
          <div
            key={index}
            className={` ${hoveredQuestionIndex === index ? 'block' : 'hidden'}`}
          >
            <div className='items-center m-16'>
            <div className='font-bold text-lg text-center'>{qna.question}</div>
            <div className="text-base tex-center w-[472px] h-[28px] mt-8 tex-center">{qna.answer}</div>
            </div>
          </div>
        ))}
      {/* </div> */}
            </div> 
            </div>
            </div>
            
            {isMobile && (
            <div className="mt-20 justify-between">
                 <div className='font-bold text-4xl'>FAQ's</div>
                 <div className={`flex-col w-full mt-20`}>
              {questions.map((item) => (
                <div key={item.id} className={`${styles.mobileFaqItem} rounded-md`}>
                  <button
                    className={`flex items-center justify-between w-full px-4 py-3 text-left text-gray-900 ${activeQuestionId === item.id ? 'bg-gray-200' : ''} ${styles.mobileFaqButton}`}
                    onClick={() => handleToggle(item.id)}
                  >
                    <span>{item.question}</span>
                    <img src={arrow} className={`w-5 h-5 transform transition-transform ${activeQuestionId === item.id ? 'rotate-90' : ''}`} alt="arrow" />
                  </button>
                  {activeQuestionId === item.id && (
                    <div className={`p-4 pb-3 ${styles.mobileFaqAnswer}`}>
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            </div>
            )}
        </div>
        </Layout>
    );
};

export default Compliance;