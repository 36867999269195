/**
 * File: Vdr.tsx
 * Description: This file contains the implementation of the Product component.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import Layout from '../component/layout';

import { Link } from 'react-router-dom';

const CapitalDelightCafe: React.FC = () => {
    // Component implementation goes here
    return (
        <Layout>
            <div className="bg-white flex flex-col">
                <div className="flex items-stretch gap-1.5 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-0">
                        <div className="items-stretch flex justify-between gap-2 pr-2">
                            <div className="text-orange-400 text-sm font-medium leading-5">
                                <Link to='/'> Home</Link>
                            </div>
                            <div className="text-orange-400 text-sm leading-5">&gt;</div>
                        </div>
                        <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                            <Link to='/product'>  Products </Link>
                        </div>
                    </div>
                    <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Busiuness Intelligence
                    </div>
                </div>
                
                <div className="self-center flex w-full max-w-[1597px] flex-col mt-10 mb-10 px-5 max-md:max-w-full max-md:mt-10">
                    
                       <iframe title="CapItAll" width="100%" height="600px" src="https://app.powerbi.com/view?r=eyJrIjoiNTBiMTdmYjAtMTliYS00NzExLWEyMGQtYmRlNjBlMWI0ZTI4IiwidCI6ImE1YzQ4OTBkLTYyM2ItNDI0MC1hMjZmLWUyMzY4MjIwMzYwZCJ9" frameBorder="0" allowFullScreen></iframe>
                    
                </div>
                <div className="bg-blue-950 self-stretch flex w-full flex-col items-center mt-2 px-20 py-12 max-md:max-w-full max-md:px-5">
                    <div className="text-white text-center text-4xl font-semibold leading-5 mt-6 max-md:max-w-full m-text-lh">
                        Overview about Business Intelligence Services
                    </div>
                    <Link to="/contact">
                    <div className="text-white text-center text-base font-semibold leading-5 whitespace-nowrap justify-center items-center rounded bg-orange-400 w-[237px] max-w-full mt-11 mb-1.5 px-16 py-4 max-md:mt-10 max-md:px-5">
                        Get Quotation
                    </div>
                    </Link>
                </div>
               

            </div>
        </Layout>
    );
};

export default CapitalDelightCafe;
