/**
 * File: header.tsx
 * Description: This file contains the Footer component for the Capitall application.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React, { useState } from 'react';
import logo from '../assets/brand/logo.png'
import locationicon from '../assets/brand/location.svg'
import phoneicon from '../assets/brand/phone.svg'
import brandingicon from '../assets/brand/branding-icon1.png'
import emailicon from '../assets/brand/emailicon.svg'
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    const [formData, setFormData] = useState({
        email: '',

    });

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        // Send the form data to the server
        sendFormDataToServer(formData);
    };

    const sendFormDataToServer = (formData: any) => {
        console.log('Sending form data to server:', formData);
        // Use an HTTP library like axios or fetch to send the data to the server
        // I am using fetch here: replace the server endpoint here with your own
        // we can make it generic by passing the endpoint as a parameter to this function and moving this to a resuable file
        // Add validation here if required

        fetch('https://capitall.io/php/newsletter-subscribe.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from the server
                console.log('Server response:', data);
            })
            .catch((error) => {
                console.error('Error sending form data:', error);
            });
    };
    return (
        <div>
            <header className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">

                <div className="flex flex-col items-stretch w-full  max-md:w-full max-md:ml-0 relative">
                    <div className="bg-sky-100 flex flex-col justify-center items-center w-full px-16 py-12 max-md:max-w-full max-md:px-5">
                        <div className="flex w-full max-w-[1501px] flex-col items-stretch mt-16 mb-2.5 max-md:max-w-full max-md:mt-10">
                            <div className="flex items-start justify-between gap-4 max-md:max-w-full max-md:flex-wrap">
                                <div className="self-stretch flex grow basis-[0%] flex-col items-start">
                                    <img
                                        loading="lazy"
                                        src={logo}
                                        srcSet={`${logo} 1x, ${logo} 2x , ${logo} 4x, ${logo} 8x, ${logo} 12x, ${logo} 16x, ${logo} 20x`}
                                        className="aspect-[1.76] object-contain object-center w-[150px] overflow-hidden max-w-full"
                                        alt=""
                                    />
                                    <div className="text-blue-950 text-2xl font-semibold leading-7 self-stretch mr-8">
                                        <br /> Manage, share, prepare and communicate with the teams and external
                                        members. <br /> <br /> We are making day to day work easy by the help of
                                        technology.
                                    </div>
                                </div>
                                <div className="col-md-4 flex flex-col items-stretch mt-2.5 self-start">
                                    <div className="text-blue-950 text-md font-semibold leading-4 tracking-wider uppercase  whitespace-nowrap">
                                        Company
                                    </div>
                                    <div className="text-stone-900 text-base font-medium leading-6 mt-14 max-md:mt-10">
                                        <Link to='/team'>About</Link> <br /> <br /> <Link to='/product'>Products </Link><br /> <br /> <Link to='/contact'>Contact Us{" "}</Link>
                                        <br /> <br /> <Link to='/privacy-policy'>Privacy Policy</Link>
                                        <br /> <br /> <Link to='/terms-conditions'>Terms Of Service</Link>
                                        <br /> <br /> <Link to='/refund-cancellations'>Refund & Cancellations</Link>
                                    </div>
                                </div>
                                <div className="flex grow basis-[0%] flex-col mt-2.5 self-start items-center">
                                    <div className="w-[66%] text-left text-blue-950 text-md font-semibold leading-4 tracking-wider uppercase">
                                        Contact Us
                                    </div>
                                    <div className="flex justify-between gap-3 mt-14 items-start max-md:mt-10">
                                        <div className="text-stone-900 text-base font-medium leading-6">
                                            <div className='flex'>
                                                <img
                                                    loading="lazy"
                                                    src={locationicon}
                                                    className="aspect-square object-contain object-center w-6 overflow-hidden mr-1"
                                                    alt="location icon"
                                                />
                                                3/15, Asaf Ali Road, New Delhi-110002</div> <br /> <br />
                                            <div className='flex'>
                                                <img
                                                    loading="lazy"
                                                    src={phoneicon}
                                                    className="aspect-square object-contain object-center w-6 overflow-hidden mr-1 "
                                                    alt="phone icon"
                                                /> +91 90151 81245</div> <br />{" "}
                                            <br />
                                            <div className='flex'>
                                                <img
                                                    loading="lazy"
                                                    src={emailicon}
                                                    className="aspect-square object-contain object-center w-6 overflow-hidden mr-1"
                                                    alt="phone icon"
                                                />contactus@CapItAll.io
                                            </div> <br /> <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex grow basis-[0%] flex-col items-stretch mt-2.5 self-start">
                                    <div className="text-blue-950 text-sm font-semibold leading-4 tracking-wider uppercase">
                                        Newsletter
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="text-zinc-400 text-base leading-7 whitespace-nowrap border border-[color:var(--gray-200,#E4E4E7)] bg-white justify-center mt-12  rounded-xl border-solid items-start max-md:mt-10 max-md:pr-5">
                                            <input
                                                type="text"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                placeholder=' Enter your email address'
                                                className='w-full h-full rounded-md p-4 pl-5 pr-16 py-6 text-gray-900'
                                            />
                                        </div>
                                        <button type='submit' className="text-white text-center w-full text-large font-medium leading-6 whitespace-nowrap justify-center items-center bg-orange-400 mt-4 px-16 py-6 rounded-lg max-md:px-5">
                                            Subscribe Now
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div className="text-blue-950 text-center text-sm leading-5 self-center mt-36 max-md:max-w-full max-md:mt-10">
                                Copyright 2023 © CapItAll India Private Limited. All Rights Reserved.
                            </div>
                        </div>
                    </div>

                    <img
                        loading="lazy"
                        src={brandingicon}
                        className="absolute -left-12 -bottom-0 aspect-square object-contain object-center w-[200px] overflow-hidden shrink-0 max-w-full max-md:mt-10"
                        alt="brand icon"
                    />
                </div>
            </header>
        </div>
    );
};

export default Footer;
