/**
 * File: carousel.tsx
 * Description: This file contains the Carousel component for the Capitall application.
 * Author: Capitall
 * Date: 2023-12-30
 * Company: Capitall.Io
 */

import React, { useState } from 'react';
import leftarrow from '../assets/brand/left-arrow.svg'
import rightarrow from '../assets/brand/right-arrow.svg'

interface ImageInfo {
    name: string;
    designation: string;
    imageUrl: string;
    bigImageUrl: string;
    description: string;
}

interface ImageListProps {
    allImages: ImageInfo[];
    imagesPerPage: number;
}

interface ImageListCarouselProps extends ImageListProps {
    onClick: (user: ImageInfo) => void;
}

const ImageListCarousel: React.FC<ImageListCarouselProps> = ({ allImages, imagesPerPage, onClick }) => {
    const [currentPage, setCurrentPage] = useState(0);

    const startIdx = currentPage * imagesPerPage;
    const endIdx = startIdx + imagesPerPage;
    const displayedImages = allImages.slice(startIdx, endIdx);

    const nextImages = () => {
        setCurrentPage((prevPage) => (prevPage + 1) % Math.ceil(allImages.length / imagesPerPage));
    };

    const prevImages = () => {
        setCurrentPage(
            (prevPage) =>
                (prevPage - 1 + Math.ceil(allImages.length / imagesPerPage)) %
                Math.ceil(allImages.length / imagesPerPage)
        );
    };

    return (
        <>
            <img
                loading="lazy"
                src={leftarrow}
                alt='left arroow'
                onClick={prevImages}
                className="aspect-[2.95] object-contain object-center w-[65px] stroke-[3px] stroke-orange-400 overflow-hidden shrink-0 max-w-full my-auto"
            />{" "}
            <div className="items-stretch flex justify-between gap-5 my-auto max-md:max-w-full max-md:flex-wrap">
                {displayedImages.map((image, index) => (
                    <div key={index} className="justify-center items-stretch flex grow basis-[0%] flex-col">

                        <img
                            loading="lazy"
                            src={image.imageUrl}
                            srcSet={`${image.imageUrl} 1x, ${image.imageUrl} 2x , ${image.imageUrl} 4x, ${image.imageUrl} 8x, ${image.imageUrl} 12x, ${image.imageUrl} 16x, ${image.imageUrl} 20x`}
                            alt={`Img ${index + 1}`}
                            className="aspect-square object-contain object-center w-[146px] overflow-hidden self-center max-w-full rounded-[50%]"
                            onClick={() => onClick(image)}
                        />{" "}
                        <div className="text-neutral-900 text-2xl font-semibold whitespace-nowrap mt-2.5">
                            {image.name}
                        </div>{" "}
                        <div className="text-zinc-500 text-xl font-semibold self-center whitespace-nowrap mt-2.5">
                            {image.designation}
                        </div>
                    </div>
                ))}
                {/* {displayedImages.map((image, index) => (
                <li key={index}>
                    <img src={image.imageUrl} alt={`Image ${index + 1}`} />
                    <div>
                        <p>{image.name}</p>
                        <p>{image.designation}</p>
                    </div>
                </li>
            ))} */}

                {/* <button onClick={prevImages}>Previous</button>
            <button onClick={nextImages}>Next</button> */}
            </div>
            <img
                loading="lazy"
                src={rightarrow}
                alt='right arrow'
                onClick={nextImages}
                className="aspect-[3] object-contain object-center w-[66px] stroke-[3px] stroke-orange-400 overflow-hidden shrink-0 max-w-full my-auto"
            />
        </>
    );

};

export default ImageListCarousel;
