/**
 * File: Vdr.tsx
 * Description: This file contains the implementation of the Product component.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import Layout from '../../component/layout';

//Image imports
import blogvirtauldataroomimg from '../../assets/images/blog/blog-virtualdataroom.webp';
import capitalluser from '../../assets/images/capitallio-blog.jpg';
import { Link } from 'react-router-dom';

const DoesYourDataTalk: React.FC = () => {
    // Component implementation goes here
    return (
        <Layout>
            <div className="bg-white flex flex-col">
                <div className="flex items-stretch gap-1.5 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-0">
                        <div className="items-stretch flex justify-between gap-2 pr-2">
                            <div className="text-orange-400 text-sm font-medium leading-5">
                                <Link to='/'> Home</Link>
                            </div>
                            <div className="text-orange-400 text-sm leading-5">&gt;</div>
                        </div>
                        <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                            <Link to='/product'>  Blog </Link>
                        </div>
                    </div>
                    <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Does Your Data Talk to You? Mine Does!
                    </div>
                </div>
                <div className="self-center w-full max-w-[1200px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="text-blue-950 text-4xl mb-5 font-semibold self-stretch max-md:max-w-full">
                        Does Your Data Talk to You? Mine Does!
                    </div>
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[100%] max-md:w-full max-md:ml-0">
                        <img
                                    loading="lazy"
                                    src={blogvirtauldataroomimg}
                                    srcSet={`${blogvirtauldataroomimg} 1x, ${blogvirtauldataroomimg} 2x , ${blogvirtauldataroomimg} 4x, ${blogvirtauldataroomimg} 8x, ${blogvirtauldataroomimg} 12x, ${blogvirtauldataroomimg} 16x, ${blogvirtauldataroomimg} 20x`}
                                    alt='Role of Virtual Data Room Post Covid'
                                    className="aspect-[1.84] object-contain object-center w-full self-stretch overflow-hidden max-md:max-w-full"
                                />
                        </div>
                    </div>
                </div>
                <div className="self-center w-full max-w-[1200px] max-md:max-w-full mb-10 max-md:mt-10">
                    <div className="text-zinc-500 text-left text-xl mt-14 self-start max-md:max-w-full">
                    In today's fast-paced business world, data is more than just a collection of numbers and facts. It's the lifeblood of decision-making and strategic planning. But the real question is, does your data communicate effectively with you? At Capitall, we've revolutionized the way businesses interact with their data through our innovative product - the Data Room.
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Data Room: A Symphony of Data Integration
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full">
                        The Data Room isn't just another data management platform. It's an ecosystem where over 300 data sources coalesce. This seamless integration means whether you're dealing with documents, spreadsheets, or complex datasets, everything is accessible under one roof. Imagine the ease of navigating through your data landscape without ever leaving the comfort of a single platform.
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Streamlining Data Management and Administration 
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full">
                        Managing and administering vast amounts of data can be daunting. The Data Room simplifies this with intuitive tools for organizing, managing, and administering your data. Gone are the days of juggling multiple platforms and interfaces. Now, everything you need is at your fingertips, integrated beautifully into a user-friendly interface.
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Business Intelligence: Transforming Data into Insights 
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full">
                        But what about making sense of this data? Our Business Intelligence tools are the game-changer here. They automate key processes like data transformation and cleaning, turning raw data into comprehensible insights. With these tools, understanding complex datasets becomes simpler, faster, and more accurate.
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Template-Driven Analytics for Consistent Results 
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full">
                        Efficiency in data analysis is crucial. That's why we've introduced customizable templates in the Data Room. These templates can be used repeatedly, saving valuable time and effort in data analysis. This feature is particularly beneficial for recurring analysis tasks, ensuring consistency and accuracy in your reports.
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Conversational AI: Your Data Dialog Partner 
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full">
                        Perhaps the most groundbreaking feature of the Data Room is its self-hosted AI capabilities. This AI empowers users to interact with their data through simple queries. Need a valuation report for your firm? Just a few clicks in the Data Room, and the AI generates a comprehensive report based on your data. It's like having a conversation with your data, and it speaks back with insights and answers.
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Insightful Intelligence: From Data to Decisions
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full">
                        Consultants and business professionals who have adopted the Data Room have reported significant time savings and increased efficiency. The ability to talk to data, to ask questions and receive insights, is not just a novelty - it's a transformative tool that redefines the essence of data interaction.
                        <br /><br />
                        At Capitall, we're not just managing data; we're fostering a new language of data interaction. The Data Room is more than a platform; it's a paradigm shift in how we view, understand, and communicate with data. 
                        <br /><br />
                        So, does your data talk to you? 
                        <br /><br />
                        With the Data Room, the answer is a resounding "Yes!" 
                    </div>
                    <div className="items-stretch flex gap-3 mt-14 mb-10 max-md:mt-10">
                        <img
                        loading="lazy"
                        src={capitalluser}
                        srcSet={`${capitalluser} 1x, ${capitalluser} 2x , ${capitalluser} 4x, ${capitalluser} 8x, ${capitalluser} 12x, ${capitalluser} 16x, ${capitalluser} 20x`}
                        alt='CapItAll'
                        className="aspect-square object-contain object-center w-20 justify-center items-center overflow-hidden shrink-0 max-w-full"
                        />
                        <div className="items-stretch flex grow basis-[0%] pt-6 flex-col">
                            <div className="text-gray-900 text-lg font-medium leading-5 whitespace-nowrap">
                                CapItAll
                            </div>
                            <div className="text-gray-500 text-lg leading-5 pt-2 whitespace-nowrap">
                                15 Dec 2023
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </Layout>
    );
};

export default DoesYourDataTalk;
