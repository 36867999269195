/**
 * File: footer.tsx
 * Description: This file contains the Footer component for the Capitall application.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import logo from '../assets/brand/logo.png'
import brandingicon from '../assets/brand/top-branding.svg'
import menuicon from '../assets/brand/menu.svg'

const Header: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth <= 768);
        });
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="flex flex-col justify-center items-stretch">
            <div className="bg-white flex w-full flex-col justify-center items-stretch px-16 py-7 max-md:max-w-full max-md:px-5">
                <div className="justify-between items-stretch flex gap-5 ml-5 mr-14 max-md:max-w-full max-md:flex-wrap max-md:mr-2.5">
                    <Link to='/'> <img
                        loading="lazy"
                        src={logo}
                        srcSet={`${logo} 1x, ${logo} 2x , ${logo} 4x, ${logo} 8x, ${logo} 12x, ${logo} 16x, ${logo} 20x`}
                        className="aspect-[1.76] object-contain object-center w-[150px] overflow-hidden shrink-0 max-w-full"
                        alt="Logo"

                    />
                    </Link>
                    {isMobile && (
                        <button onClick={toggleMenu}>
                            <img src={menuicon} alt='menu' />
                        </button>
                    )}
                    <nav className={`items-stretch self-center flex justify-between gap-14 my-auto p-2.5 max-md:max-w-full max-md:flex-wrap web-menu ${isMobile ? 'hidden' : 'block'}`}>
                        <div className="text-blue-950 text-base font-medium leading-6 uppercase grow whitespace-nowrap">
                            <NavLink
                                to="/"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }
                            >
                                Home
                            </NavLink>
                        </div>
                        <div className="text-blue-950 text-base font-medium leading-6 uppercase">
                            <NavLink to="/product"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>
                                Products
                            </NavLink>
                        </div>
                        <div className="text-blue-950 text-base font-medium leading-6 uppercase">
                            <NavLink to="/team"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>
                                Our Team
                            </NavLink>
                        </div>
                        <div className="text-blue-950 text-base font-medium leading-6 uppercase">
                            <NavLink to="/case-studies"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>
                                Case Studies
                            </NavLink>
                        </div>
                        <div className="text-blue-950 text-base font-medium leading-6 uppercase">
                            <NavLink to="/contact"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>
                                Contact
                            </NavLink>
                        </div>
                        {/*}<div className="text-blue-950 text-base font-medium leading-6 uppercase grow whitespace-nowrap">
                            <NavLink to="/career"
                                className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                }>
                                Career
                            </NavLink>
                            </div>{*/}
                    </nav>
                    {isMobile && isMenuOpen && (
                        <div className="origin-top-right absolute right-0 mt-16 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                <Link to='/' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">HOME</Link>
                                <Link to='/product' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">PRODUCTS</Link>
                                <Link to='/team' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">OUR TEAM</Link>
                                {/* <Link to='/blog' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">BLOG</Link> */}
                                <Link to='/case-studies' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">CASE STUDIES</Link>
                                <Link to='/contact' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">CONTACT</Link>
                                {/*}<Link to='/career' className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">CAREER</Link>{*/}
                            </div>
                        </div>
                    )}

                </div>
                <img
                    loading="lazy"
                    src={brandingicon}
                    className="absolute pointer-events-none right-0 top-0 aspect-square object-contain object-center overflow-hidden shrink-0 max-w-full max-md:mt-10"
                    alt="brand icon"
                />
            </div>
        </header >
    );
};

export default Header;
