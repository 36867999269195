/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: footer.tsx
 * Description: This file is the blog page component.
 */

import React from 'react';
import Layout from '../component/layout';
import { Link } from 'react-router-dom';

const RefundPage: React.FC = () => {
    // Add your component logic here

    return (
        <Layout>
            <div className="bg-white flex flex-col">

                <div className="items-stretch flex gap-0 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-2 pr-2">
                        <div className="text-orange-400 text-sm font-medium leading-5">
                            Home
                        </div>
                        <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    </div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Refund & Cancellations
                    </div>
                </div>
                <div className="self-center w-full max-w-[1200px] max-md:max-w-full mb-10 max-md:mt-10">
                    <div className="text-zinc-500 text-left text-xl mt-14 self-start max-md:max-w-full leading-8">
                        These Terms & Conditions ("Terms") govern your access to and use of Capitall's website (the "Site") and services (the "Services"). By accessing or using the Site or Services, you agree to be bound by these Terms.
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Account Creation & Use
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        <ul className='mt-5'>
                            <li className='list-disc ml-5'>To access certain Services, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account.</li>
                            <li className='list-disc ml-5'>You agree to provide accurate, current, and complete information when creating or updating your account.</li>
                            <li className='list-disc ml-5'>You may not use the Services for any illegal or unauthorized purpose. You agree to comply with all applicable laws and regulations.</li>
                        </ul>
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Subscriptions & Payments
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        <ul className='mt-5'>
                            <li className='list-disc ml-5'><b>Subscription Plans:</b> We offer various subscription plans for the Services, including monthly, yearly, and one-time payment options. Details of each plan and its associated fees are outlined on the Site.</li>
                            <li className='list-disc ml-5'><b>Payment Terms:</b> Payment for subscriptions is due in advance and will be billed automatically to the payment method you provide.</li>
                            <li className='list-disc ml-5'><b>Cancellation:</b> You may cancel your subscription at any time. Cancellation will take effect at the end of your current billing cycle.</li>
                            <li className='list-disc ml-5'><b>Refunds:</b> Refunds are generally not provided for subscription fees. However, we may consider refund requests on a case-by-case basis.</li>
                        </ul>
                    </div>
                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Refunds
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                         <ul className='mt-5'>
                            <li className='list-disc ml-5'><b>General Policy: </b>Due to the nature of our Services, we generally do not offer refunds for subscription fees. This is because you receive immediate access to the Services upon payment.</li>
                            <li className='list-disc ml-5'><b>Exceptions: </b>We may consider refund requests under exceptional circumstances, such as:
                                <ul>
                                    <li className='list-decimal ml-10'><b>Technical Issues: </b>If you experience significant technical problems that prevent you from using the Services despite our reasonable attempts to resolve the issue.</li>
                                    <li className='list-decimal ml-10'><b>Billing Errors: </b>If you were charged in error or for a service you did not receive.</li>
                                </ul>
                            </li>
                            <li className='list-disc ml-5'><b>Refund Requests: </b>All refund requests must be made within 7 days of your payment. Please contact our customer support team with your request and a detailed explanation.</li>
                        </ul>
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Special Considerations
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        <ul className='mt-5'>
                            <li className='list-disc ml-5'><b>One-Time Payments: </b>One-time payments for services are typically non-refundable, unless otherwise specified.</li>
                            <li className='list-disc ml-5'><b>Yearly Subscriptions: </b>For yearly subscriptions, you may request a prorated refund if you cancel within 7 days of your payment. </li>
                        </ul>
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Processing Time
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        Approved refunds may take up to [Number] business days to process, depending on your payment method.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Changes to this Policy
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        We may update this Refund & Cancellation Policy from time to time. We encourage you to review this policy periodically.
                    </div>

                    <div className="text-blue-950 text-xl text-left mb-2 mt-10 font-semibold self-stretch max-md:max-w-full">
                        Contact Us
                    </div>
                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        If you have any questions or concerns regarding our Refund & Cancellation Policy, please don't hesitate to contact our customer support team at support@capitall.io
                    </div>

                    <div className="text-zinc-500 text-left text-xl self-start max-md:max-w-full leading-8">
                        <b>We appreciate your understanding and hope you enjoy our Services! </b>
                    </div>

                </div>

            </div>
        </Layout>
    );
};

export default RefundPage;
