/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: about.tsx
 * Description: This file contains the About page component.
 */

import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../component/layout';
import Desktopimage from '../../assets/brand/Desktop.png';
import playButton from '../../assets/brand/play.svg';
import arrowRight from '../../assets/brand/arrow-right.svg';
import arrow from '../../assets/brand/akar-icons_chevron-right.svg';
import frame from '../../assets/brand/Col.png';
import smallImage from '../../assets/brand/topImage.png';
import collab from '../../assets/brand/collab.svg';
import lock from '../../assets/brand/lock.svg';
import document from '../../assets/brand/document.svg';
import dataPhoto from '../../assets/brand/data-2.svg';
import mac from '../../assets/brand/Macbook.png';
import dashboardVideo from '../../assets/videos/dashboard.webm';
import searchFeaVideo from '../../assets/videos/search_feature.webm';
import FeaturesVideo from '../../assets/videos/features.webm';
import grantVideo from '../../assets/videos/grant_perm.webm';
import styles from './DataRoomPage.module.css';

// import editFeature from '../../assets/brand/Editfeature';
interface FaqItem {
  id: number;
  question: string;
  answer: string;
}

const DataRoomPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeVideo, setActiveVideo] = useState<string | null>(grantVideo);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 768);
    });
  }, []);
  // Your component logic here
  interface QuestionAnswerPair {
    id: number;
    question: string;
    answer: string;
  }

  const [hoveredQuestionIndex, setHoveredQuestionIndex] = useState(-1);


  const questions: QuestionAnswerPair[] = [
    {
      id: 1,
      question: "Why Should I Choose CapitALL's Dataroom?",
      answer: " CapitALL's Dataroom streamlines data management with features like automated workflows, efficient collaboration, and granular permission access to secure your documentation. It offers convenient document retrieval and integrates multiple data sources, ensuring a seamless data management experience."
    },
    {
      id: 2,
      question: "What is the Browser and Version Compatibility of CapitALL's Dataroom? ",
      answer: " CapitALL's Dataroom is compatible with all major browsers, including Chrome, Firefox, Safari, and Edge. This ensures seamless functionality and optimal performance, keeping you updated with the latest features and security enhancements."
    },
    {
      id: 3,
      question: 'How Can I Track Data Easily in Dataroom? ',
      answer: "Tracking data in CapitALL's Dataroom is simple and efficient. The platform provides real-time data tracking, interactive dashboards, and comprehensive reports. You can monitor changes, access historical data, and gain insights effortlessly."
    },
    {
      id: 4,
      question: 'How Can I Restrict Access to Personnel in Dataroom? ',
      answer: " CapitALL's Dataroom offers granular permission access, allowing you to control who can view, edit, or share your documents. You can customize access levels for team members or external collaborators, ensuring only authorized personnel have access to sensitive data."
    },
    {
      id: 5,
      question: 'Is There More to Dataroom Than Storage? ',
      answer: "Yes, CapitALL's Dataroom goes beyond storage. It offers automated workflows, real-time collaboration, advanced data analysis, and robust security features. These capabilities streamline your processes, enhance productivity, and provide valuable insights."
    },
    {
      id: 6,
      question: "Is Dataroom Relevant for My Field? ", answer: "CapitALL's Dataroom is a versatile solution tailored for various industries, including finance, healthcare, and legal sectors. It offers customized workflows and industry-specific features to meet your unique data management needs, ensuring compliance and security."
    },
    {
      id: 7,
      question: "How Can I Learn More About CapitALL's Dataroom?  ", answer: ("To learn more about CapitALL's Dataroom and how it can transform your data management, contact us team for a personalized demo and consultation.")
    }
  ];

  const handleQuestionHover = (index: number) => {
    setHoveredQuestionIndex(index);
  };

  const [activeQuestionId, setActiveQuestionId] = useState<number | null>(1);

  const handleToggle = (id: number) => {
    setActiveQuestionId(id === activeQuestionId ? null : id);
    // const line = document.getElementsByClassName('line');

    setActiveVideo(
      id === 1
        ? grantVideo
        : id === 2
          ? FeaturesVideo
          : id === 3
            ? dashboardVideo
            : id === 4
              ? searchFeaVideo
              : null
    );

    // Ensure the video reloads/restarts
    if (videoRef.current) {
      videoRef.current.load(); // Reload video when activeVideo changes
    }

  };

  const data: FaqItem[] = [
    {
      id: 1,
      question: "Data Security and Access Control",
      answer: "Granular Permission Levels: Ensure that only authorized personnel can access sensitive information. Secure Document Storage: Protect your documents with industry-leading security protocols. ",
    },
    {
      id: 2,
      question: "Efficient Document Management",
      answer: "Easy Document Retrieval: Find the documents you need quickly with our intuitive search functionality.Centralized Data Storage: Keep all your important documents in one secure, accessible location. ",
    },
    {
      id: 3,
      question: "Streamlined Workflows",
      answer: "Real-Time Collaboration: Work together with your team on documents in real-time. Approval Workflow: Streamline the process of document review and approval. ",
    },
    {
      id: 4,
      question: "Integration and Automation",
      answer: "Seamless Integration: Integrate with your existing data sources for comprehensive data management. Automated Workflows: Automate repetitive tasks to save time and reduce errors. ",
    },
    {
      id: 5,
      question: "Transparent Automations",
      answer: "Answer 2 for File 2",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 4;
  const totalQuestions = data.length;


  const startIndex = (currentPage - 1) * questionsPerPage;
  const endIndex = Math.min(startIndex + questionsPerPage, totalQuestions);
  const slicedData = data.slice(startIndex, endIndex);





  return (
    <Layout>
      <div className="ease-in duration-300 mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <Helmet>
          <title>Product Page - capitall.io</title>
          <meta name="description" content="Welcome to our website. Learn about our services and more." />
        </Helmet>
        <div className='justify-items-center'>
          <div className={`text-center mt-16 font-bold ${isMobile ? 'text-2xl' : 'text-5xl'}`}>Virtual Data Room</div>
          <p className={`mt-8 text-center ${isMobile ? 'mx-1' : 'mx-8'} ${isMobile ? 'text-base' : 'text-xl'}`}>Effortlessly Manage Your Data and Collaborate with Confidence </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <a className=" flex select-none mt-12 rounded-full bg-blue-950 py-2 px-4 font-sans text-2xl font-medium text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] " type="button" href='/contact'> Request A Demo<img src={arrowRight} className='py-2 pl-2' /></a>
          <div className=' mt-12'>
            <img
              loading="lazy"
              src={Desktopimage}
              alt='Interface'
              className=" "
            />
          </div>
          <p className='mt-6 text-blue-950 font-bold flex'><img src={playButton} className='mr-4' />Watch Demo</p>

        </div>
        <div className="flex flex-col justify-center items-center mt-[8rem]">
          <div className='text-center font-semibold text-3xl'>Benefits</div>
          <p className={`mt-4 mb-2 text-center  ${isMobile ? 'mx-0.5' : 'mx-20'} ${isMobile ? 'text-base' : 'text-xl'}`}>Controlled Sharing & Efficient Collaboration with external stakeholders  </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 my-5  ">
            <div className='flex flex-col items-center mx-4 my-5 w-[244px] '>
              <div className='relative'>
                <div className='bg-orange-200 rounded-full w-16 h-16'><img src={collab} className='pl-5 pt-5' /></div></div>
              <h4 className='text-xl font-semibold absoulte mt-4 text-center'>Confident & Seamless Collaboration</h4>
              <p className='text-l font-normal mt-2 text-center'> Direct access to data and analysis without delays. </p>
            </div>
            <div className='flex flex-col items-center mx-4 my-5 w-[244px] '>
              <div className='bg-blue-100 rounded-full w-16 h-16 border-none'><img src={lock} className='blue-200 pl-5 pt-5' /></div>
              <h4 className='text-xl font-semibold mt-4 text-center'>Granular Permission Access </h4>
              <p className='text-l font-normal mt-2 text-center'>Secure documentation with detailed access controls</p>
            </div>
            <div className='flex flex-col items-center mx-4 my-5 w-[244px] '>
              <div className='bg-blue-200 rounded-full w-16 h-16 border-none'><img src={document} className='pl-5 pt-5' /></div>
              <h4 className='text-xl font-semibold mt-4 text-center'>Convenient Document Retrieva</h4>
              <p className='text-l font-normal mt-2 text-center'>Quickly find and retrieve documents as needed. </p>
            </div>
            <div className='flex flex-col items-center mx-4 my-5 w-[244px] '>
              <div className='bg-slate-200 rounded-full w-16 h-16 border-none'><img src={dataPhoto} className='pl-5 pt-5' /></div>
              <h4 className='text-xl font-semibold mt-4 text-center'>Manageable Integration of All Data Sources</h4>
              <p className='text-l font-normal mt-2 text-center'>Focused consolidation for streamlined operations. </p>
            </div>

            {/* <div className='flex flex-col items-center mx-4 my-5'>
                <div className='bg-green-200 rounded-full w-16 h-16 border-none'></div>
                <h4 className='text-xl font-semibold mt-4'>Manageable Integration of All Data Sources</h4>
                <p className='text-l font-normal mt-2 w-[66%]'> Focused consolidation of information for streamlined operations. </p>
            </div> */}
          </div>
        </div>
        <div className="mt-20">
          <h2 className="text-center font-bold text-3xl mb-8">Key Features</h2>

          <div className={`flex flex-col md:flex-row gap-8 justify-center items-center`}>
            {/* Flex container for video and features */}
            <div className={`${styles.videoContainer} relative w-full md:w-1/2 `}>
              {/* Video container with responsive width */}
              {activeVideo && (
                <video
                  ref={videoRef}
                  controls
                  width="100%"
                  height="100%"
                  className="object-cover relative border-4 border-blue-950 rounded-lg"
                >
                  <source src={activeVideo} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
            <div className={`flex-col w-full max-w-[400px] mt-4 ${isMobile ? 'mx-auto' : 'ml-20'}`}>
              {slicedData.map((item) => (
                <div className={`pb-2 `} key={item.id}>
                  <span className={` absolute w-1 ease-in-out duration-300 ${activeQuestionId === item.id ? 'bg-orange-500' : 'bg-slate-300'} ${activeQuestionId === item.id ? ' h-36' : 'h-6'}`}>
                  </span>
                  <button
                    className={`flex mt-2 h-9 ml-6 items-start mb-2 text-left ${activeQuestionId === item.id && item.id === 1 ? 'faq-accordion-question-button-active' : ''} ${isMobile ? 'text-lg' : 'text-xl'} ${isMobile ? 'max-w-60' : ''}`}
                    onClick={() => handleToggle(item.id)}
                  >
                    {item.question}
                  </button>
                  {activeQuestionId === item.id && (
                    <div className={` h-24 text-slate-900 py-4 mt-1 ml-8 w-[344px] mb-8 ${isMobile ? 'text-sm' : 'text-md'} `}>
                      <p> {item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={`flex mt-20 justify-center ${isMobile ? '' : 'mx-16'}  ${isMobile ? 'flex-col' : ''}`}>
          {/* <div className={`font-bold text-3xl ${isMobile ? 'mr-8' : ''} `}>Get Started</div> */}
          <div className={`space-x-2 flex ${isMobile ? 'mt-4' : ''}`}>
            <a className='select-none w-64 rounded-lg bg-blue-950 py-2 px-2 font-sans text-base font-medium text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85]' href='/contact'>Schedule a Demo</a>
            <a className='select-none w-64 rounded-lg  py-2 px-2 font-sans text-base font-medium shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] border-2 border-blue-950 ' href='/contact'>Connect with Us</a>
          </div>
        </div>

        <div className={`mt-20 mx-16 justify-between ${isMobile ? 'hidden' : 'block'}`}>
          <div className='font-bold text-4xl'>Frequently Asked Quetions</div>
          <div className='flex relative' >
            <div className='flex-col w-[640px] h-[536px] top-[144px] mt-20 abosulte border-slate-200 border-2'>
              {questions.map((qna, index) => (
                <div
                  key={index}
                  className={`h-[76px] px-6 py-4 flex shadow hover:bg-gray-200 ${hoveredQuestionIndex === index ? 'bg-gray-300' : ''
                    }`}
                  onMouseEnter={() => handleQuestionHover(index)}
                  onMouseLeave={() => setHoveredQuestionIndex(-1)}
                >
                  <div className='rounded-full w-6 h-6 bg-indigo-300 mr-6 hover:bg-indigo-600'></div>
                  <div className="w-[460px]">{qna.question}</div>
                  <div><img src={arrow} className='w-6 h-6  mx-4 my-3' alt='>' /></div>
                </div>
              ))}
            </div>
            <div className='flex-col w-[648px] h-[472px] rounded-2xl bg-slate-50 mt-16'>
              {questions.map((qna, index) => (
                <div
                  key={index}
                  className={` ${hoveredQuestionIndex === index ? 'block' : 'hidden'}`}
                >
                  <div className='items-center m-16'>
                    <div className='font-bold text-lg text-center'>{qna.question}</div>
                    <div className="text-base tex-center w-[472px] h-[28px] mt-8 tex-center">{qna.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Frequently Asked Questions (Mobile Only) */}
        {/* Mobile FAQs (Shown only on mobile) */}
          {isMobile && ( // Conditionally render mobile FAQs only if isMobile is true
           <div className="mt-20 justify-between">
                 <div className='font-bold text-4xl'>FAQ's</div>
            <div className={`flex-col w-full mt-20`}>
              {questions.map((item) => (
                <div key={item.id} className={`${styles.mobileFaqItem} rounded-md`}>
                  <button
                    className={`flex text-sm items-center justify-between w-full px-4 py-3 text-left text-gray-900 ${activeQuestionId === item.id ? 'bg-gray-200' : ''} ${styles.mobileFaqButton}`}
                    onClick={() => handleToggle(item.id)}
                  >
                    <span>{item.question}</span>
                    <img src={arrow} className={`w-5 h-5 transform transition-transform ${activeQuestionId === item.id ? 'rotate-90' : ''}`} alt="arrow" />
                  </button>
                  {activeQuestionId === item.id && (
                    <div className={`p-4 font-normal pb-3 ${styles.mobileFaqAnswer} text-sm`}>
                      <p className="text-gray-700">{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            </div>
          
          )}
      </div>
    </Layout>
  );
};

export default DataRoomPage;