/**
 * File: Vdr.tsx
 * Description: This file contains the implementation of the Product component.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import Layout from '../component/layout';

//Image imports
import producttopimg from '../assets/images/product/product-top-img.webp';
import productbottomimg from '../assets/images/product/product-bottom-img.webp';
import productcenterimg from '../assets/images/product/product-center-img.webp';
import tickicon from '../assets/images/product/tick-icon.svg';
import accountingicon from '../assets/images/product/accounting-icon.svg';
import businessicon from '../assets/images/product/business-icon.svg';
import equityicon from '../assets/images/product/equity-icon.svg';
import govticon from '../assets/images/product/govt-icon.svg';
import lawicon from '../assets/images/product/law-icon.svg';
import ngoicon from '../assets/images/product/ngo-icon.svg';
import ventureicon from '../assets/images/product/venture-icon.svg';
import blankicon from '../assets/images/product/blank-icon.svg';
import investmenticon from '../assets/images/product/investment-icon.svg';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../component/scrollAnimation';

const Vdr: React.FC = () => {
    // Component implementation goes here
    return (
        <Layout>
            <div className="bg-white flex flex-col">
                <div className="flex items-stretch gap-1.5 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-0">
                        <div className="items-stretch flex justify-between gap-2 pr-2">
                            <div className="text-orange-400 text-sm font-medium leading-5">
                                <Link to='/'> Home</Link>
                            </div>
                            <div className="text-orange-400 text-sm leading-5">&gt;</div>
                        </div>
                        <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                            <Link to='/product'>  Products </Link>
                        </div>
                    </div>
                    <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Virtual Data Room
                    </div>
                </div>
                <div className="self-center w-full max-w-[1597px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[56%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={producttopimg}
                                srcSet={`${producttopimg} 1x, ${producttopimg} 2x , ${producttopimg} 4x, ${producttopimg} 8x, ${producttopimg} 12x, ${producttopimg} 16x, ${producttopimg} 20x`}
                                alt='product-top-img'
                                className="aspect-[1.69] object-contain object-center w-full overflow-hidden max-w-[707px]"
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col mt-8 items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-black text-3xl font-semibold self-stretch max-md:max-w-full">
                                    Virtual Data room
                                    <br />
                                    <br />
                                </div>
                                <div className="text-zinc-500 text-justify text-lg self-stretch mt-8 max-md:max-w-full">
                                    A virtual data room (VDR) is a secure online repository for
                                    storing and sharing confidential documents. It is used by
                                    businesses of all sizes to facilitate mergers and acquisitions
                                    (M&A), fundraising, due diligence, and other transactions.<br /><br />VDRs
                                    offer a number of advantages over traditional physical data
                                    rooms. They are more secure, as they are hosted in the cloud and
                                    protected by strong encryption. They are also more convenient,
                                    as users can access them from anywhere with an internet
                                    connection. And they are more cost-effective, as there is no
                                    need to rent or build a physical space.
                                </div>
                                {/*<img
                                    loading="lazy"
                                    src={chaticon}
                                    alt='chat-icon'
                                    className="aspect-square object-contain object-center w-[101px] shadow-lg overflow-hidden max-w-full mr-10 mt-24 max-md:mr-2.5 max-md:mt-10"
                                />*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-blue-950 self-stretch flex w-full flex-col items-center mt-2 px-20 py-12 max-md:max-w-full max-md:px-5">
                    <div className="text-white text-center text-4xl font-semibold leading-5 mt-6 max-md:max-w-full m-text-lh">
                        Overview about virtual data room
                    </div>
                    <Link to="/contact">
                    <div className="text-white text-center text-base font-semibold leading-5 whitespace-nowrap justify-center items-center rounded bg-orange-400 w-[237px] max-w-full mt-11 mb-1.5 px-16 py-4 max-md:mt-10 max-md:px-5">
                        Get Quotation
                    </div>
                    </Link>
                </div>
                <ScrollAnimation>
                <div className="self-center flex w-full max-w-[1527px] flex-col mt-24 px-5 max-md:max-w-full max-md:mt-10">
                    <div className="text-blue-950 text-3xl font-semibold self-start max-md:max-w-full m-auto">
                        Benefits of using A Virtual Data Room
                    </div>
                    <div className="text-zinc-500 text-center text-xl mt-4 self-start max-md:max-w-full m-auto">
                        There are many benefits to using a virtual data room, including:
                    </div>
                    <div className="self-stretch mt-24 max-md:max-w-full max-md:mt-10 mb-20">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-5 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Increased security:{" "}
                                        <span className=" leading-6">
                                            VDRs are hosted in the cloud and protected by strong
                                            encryption, making them much more secure than traditional
                                            physical data rooms.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Convenience:{" "}
                                        <span className=" leading-6">
                                            Users can access VDRs from anywhere with an internet
                                            connection, making them ideal for remote teams and
                                            international transactions.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto mt-1">
                                        Cost-effectiveness:{" "}
                                        <span className=" leading-6">
                                            VDRs are a more cost-effective solution than renting or
                                            building a physical data room.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[1007px] max-w-full mt-20 self-start max-md:mt-10 m-auto">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-7 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-7 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Increased efficiency:{" "}
                                        <span className=" leading-6">
                                            VDRs can help to streamline the M&A, fundraising, and due
                                            diligence processes.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-7 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto mt-1.5">
                                        Improved collaboration:{" "}
                                        <span className=" leading-6">
                                            VDRs allow multiple users to view and collaborate on
                                            documents in real time.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
                <ScrollAnimation>
                <div className="bg-red-50 self-stretch flex w-full flex-col items-center mt-24 pt-12 pb-6 px-16 max-md:max-w-full max-md:mt-10 max-md:px-5">
                    <div className="flex w-full max-w-[1401px] flex-col items-stretch mt-9 max-md:max-w-full">
                        <div className="flex-col overflow-hidden relative flex min-h-[568px] w-full items-center pt-12 max-md:max-w-full">
                            <img
                                loading="lazy"
                                src={productcenterimg}
                                srcSet={`${productcenterimg} 1x, ${productcenterimg} 2x , ${productcenterimg} 4x, ${productcenterimg} 8x, ${productcenterimg} 12x, ${productcenterimg} 16x, ${productcenterimg} 20x`}
                                alt='product-center-img'
                                className="absolute h-full w-full object-cover object-center inset-0"
                            />
                            <img
                                loading="lazy"
                                src={blankicon}
                                alt='blank icon'
                                className="aspect-[1.06] object-contain object-center w-[86px] overflow-hidden max-w-full mt-48 max-md:mt-10"
                            />
                            <div className="relative text-white text-center text-3xl font-semibold leading-10 tracking-tight self-stretch justify-center items-center mt-5 pt-36 pb-14 px-16 rounded-none max-md:max-w-full max-md:pt-10 max-md:px-5">
                                HOW DOES A VIRTUAL DATA ROOM WORK?
                            </div>
                        </div>
                        <div className="text-black text-xl font-medium mt-14 max-md:max-w-full max-md:mt-10">
                            A VDR typically works as follows:
                        </div>
                        <div className="text-zinc-500 text-xl mt-4 pl-10 max-md:max-w-full">
                            1. The organisation that wants to share documents creates a VDR account
                            and invites the users who need access.
                        </div>
                        <div className="text-zinc-500 text-xl mt-5 pl-10 max-md:max-w-full">
                            2. The users log in to the VDR and upload the documents they want to
                            share.
                        </div>
                        <div className="text-zinc-500 text-xl mt-5 pl-10 max-md:max-w-full">
                            3. The documents are stored in a secure, password-protected
                            environment.
                        </div>
                        <div className="text-zinc-500 text-xl mt-5 pl-10 max-md:max-w-full">
                            {" "}
                            4. The users can view, download, and print the documents as needed.
                        </div>
                        <div className="text-zinc-500 text-xl pl-10 mt-5 max-md:max-w-full">
                            {" "}
                            5. The organisation can revoke access to the documents at any time.
                            <br />
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
                <ScrollAnimation>
                <div className="self-center flex w-full max-w-[1460px] flex-col items-stretch mt-20 px-5 max-md:max-w-full max-md:mt-10">
                    <div className="text-gray-950 text-3xl font-semibold max-md:max-w-full">
                        Who Uses Virtual Data Room ?
                    </div>
                    <div className="self-center w-full max-w-[1392px] mt-20 px-px max-md:max-w-full max-md:mt-10">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                                <div className="justify-center items-center border bg-white flex grow flex-col w-full px-14 py-12 rounded-2xl border-solid border-orange-400 max-md:mt-7 max-md:px-5">
                                    <div className="justify-center items-center shadow-lg bg-white flex w-[139px] flex-col h-[139px] px-7 rounded-[76px] max-md:px-5">
                                        <img
                                            loading="lazy"
                                            src={businessicon}
                                            alt='Businesses of all sizes'
                                            className="aspect-square object-contain object-center w-full overflow-hidden"
                                        />
                                    </div>
                                    <div className="text-neutral-900 text-xl font-medium self-stretch whitespace-nowrap mt-2.5">
                                        Businesses of all sizes
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                                <div className="justify-center items-center border bg-white flex grow flex-col w-full p-12 rounded-2xl border-solid border-orange-400 max-md:mt-7 max-md:px-5">
                                    <div className="justify-center items-center shadow-lg bg-white flex w-[139px] flex-col h-[139px] px-7 rounded-[76px] max-md:px-5">
                                        <img
                                            loading="lazy"
                                            src={govticon}
                                            alt='Government Agencies'
                                            className="aspect-square object-contain object-center w-full overflow-hidden"
                                        />
                                    </div>
                                    <div className="text-neutral-900 text-xl font-medium self-stretch whitespace-nowrap mt-2.5">
                                        Government Agencies
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                                <div className="justify-center items-center border bg-white flex grow flex-col w-full px-20 py-12 rounded-2xl border-solid border-orange-400 max-md:mt-7 max-md:px-5">
                                    <div className="justify-center items-center shadow-lg bg-white flex w-[139px] flex-col h-[139px] px-7 rounded-[76px] max-md:px-5">
                                        <img
                                            loading="lazy"
                                            src={accountingicon}
                                            alt='Accounting Firms'
                                            className="aspect-square object-contain object-center w-full overflow-hidden"
                                        />
                                    </div>
                                    <div className="text-neutral-900 text-xl font-medium self-stretch whitespace-nowrap mt-2.5">
                                        Accounting Firms
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                                <div className="justify-center items-center border bg-white flex grow flex-col w-full px-20 py-12 rounded-2xl border-solid border-orange-400 max-md:mt-7 max-md:px-5">
                                    <div className="justify-center items-center shadow-lg bg-white flex w-[139px] flex-col h-[139px] px-7 rounded-[76px] max-md:px-5">
                                        <img
                                            loading="lazy"
                                            src={lawicon}
                                            alt='Law Firms'
                                            className="aspect-square object-contain object-center w-full overflow-hidden"
                                        />
                                    </div>
                                    <div className="text-neutral-900 text-xl font-medium whitespace-nowrap mt-2.5">
                                        Law Firms
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="justify-center self-center w-full max-w-[1392px] mt-10 max-md:max-w-full">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-[76%] max-md:w-full max-md:ml-0">
                                <div className="grow max-md:max-w-full max-md:mt-8">
                                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                                            <div className="justify-center items-center border bg-white flex grow flex-col w-full px-16 py-12 rounded-2xl border-solid border-orange-400 max-md:mt-7 max-md:px-5">
                                                <div className="justify-center items-center shadow-lg bg-white flex w-[139px] flex-col h-[139px] px-7 rounded-[76px] max-md:px-5">
                                                    <img
                                                        loading="lazy"
                                                        src={investmenticon}
                                                        alt='Investment Banks'
                                                        className="aspect-square object-contain object-center w-full overflow-hidden"
                                                    />
                                                </div>
                                                <div className="text-neutral-900 text-xl font-medium self-stretch whitespace-nowrap mt-2.5">
                                                    Investment Banks
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                            <div className="justify-center items-center border bg-white flex grow flex-col w-full px-10 py-12 rounded-2xl border-solid border-orange-400 max-md:mt-7 max-md:px-5">
                                                <div className="justify-center items-center shadow-lg bg-white flex w-[139px] flex-col h-[139px] px-7 rounded-[76px] max-md:px-5">
                                                    <img
                                                        loading="lazy"
                                                        src={ngoicon}
                                                        alt='Non-Profit Organisations'
                                                        className="aspect-square object-contain object-center w-full overflow-hidden"
                                                    />
                                                </div>
                                                <div className="text-neutral-900 text-xl font-medium self-stretch whitespace-nowrap mt-2.5">
                                                    Non-Profit Organisations
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                            <div className="justify-center items-center border bg-white flex grow flex-col w-full px-16 py-12 rounded-2xl border-solid border-orange-400 max-md:mt-7 max-md:px-5">
                                                <div className="justify-center items-center shadow-lg bg-white flex w-[139px] flex-col h-[139px] px-7 rounded-[76px] max-md:px-5">
                                                    <img
                                                        loading="lazy"
                                                        src={equityicon}
                                                        alt='Private Equity Firms'
                                                        className="aspect-square object-contain object-center w-full overflow-hidden"
                                                    />
                                                </div>
                                                <div className="text-neutral-900 text-xl font-medium self-stretch whitespace-nowrap mt-2.5">
                                                    Private Equity Firms
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[24%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="justify-center items-center border bg-white flex grow flex-col w-full px-14 py-12 rounded-2xl border-solid border-orange-400 max-md:mt-8 max-md:px-5">
                                    <div className="justify-center items-center shadow-lg bg-white flex w-[139px] flex-col h-[139px] px-7 rounded-[76px] max-md:px-5">
                                        <img
                                            loading="lazy"
                                            src={ventureicon}
                                            alt='Venture Capital Firms'
                                            className="aspect-square object-contain object-center w-full overflow-hidden"
                                        />
                                    </div>
                                    <div className="text-neutral-900 text-xl font-medium self-stretch whitespace-nowrap mt-2.5">
                                        Venture Capital Firms
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
                <div className="flex-col overflow-hidden self-stretch relative flex min-h-[389px] w-full items-center mt-20 px-20 py-12 max-md:max-w-full max-md:mt-10 max-md:px-5">
                    <img
                        loading="lazy"
                        src={productbottomimg}
                        srcSet={`${productbottomimg} 1x, ${productbottomimg} 2x , ${productbottomimg} 4x, ${productbottomimg} 8x, ${productbottomimg} 12x, ${productbottomimg} 16x, ${productbottomimg} 20x`}
                        alt='product-bottom-img'
                        className="absolute h-full w-full object-cover object-center inset-0"
                    />
                    <div className="relative text-white text-3xl font-semibold whitespace-nowrap mt-14 max-md:mt-10">
                        Conclusion
                    </div>
                    <div className="relative text-white text-center text-2xl w-[1401px] mt-3.5 mb-8 max-md:max-w-full">
                        Virtual data rooms are a secure, convenient, and cost-effective way to
                        store and share confidential documents. They are used by businesses of
                        all sizes to facilitate a variety of transactions, including M&A,
                        fundraising, due diligence, and others. If you need to share
                        confidential information with third parties, a VDR is a great option.
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default Vdr;
