/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: about.tsx
 * Description: This file contains the About page component.
 */

import React from 'react';
import Layout from '../component/layout';
import { VuesaxBoldArrowUp1 } from "../assets/icons/VuesaxBoldArrowUp1";
import { VuesaxBoldTickCircle } from "../assets/icons/VuesaxBoldTickCircle";
import { VuesaxLinearChart } from "../assets/icons/VuesaxLinearChart";
import { VuesaxLinearCloudConnection1 } from "../assets/icons/VuesaxLinearCloudConnection1";
import { VuesaxLinearDirectSend } from "../assets/icons/VuesaxLinearDirectSend";
import { VuesaxLinearHierarchy1 } from "../assets/icons/VuesaxLinearHierarchy1";
import { VuesaxLinearPeople1 } from "../assets/icons/VuesaxLinearPeople1";
import aerial from "../assets/images/case-studies/aerial-view-shanghai-overpass-night@2x.png";
import urban from "../assets/images/case-studies/urban-traffic-with-cityscape@2x.png";
import drones from "../assets/images/case-studies/drones-used-aerial-bridge-building-undercut-traffic-develop-muni@2x.png";
import business from "../assets/images/case-studies/business-person-futuristic-business-environment@2x.png";
import cine from "../assets/images/case-studies/cinematic-style-mall@2x.png";
import hologram from "../assets/images/case-studies/hologram-social-media-icons-sunset-panoramic-cityscape-bangkok-s.png";
import trip from "../assets/images/case-studies/trip-toll-highway-athens-thessaloniki-past-cars-gas-stations-tol@2x.png";
import el1 from "../assets/images/case-studies/element-1@2x.png";
import el2 from "../assets/images/case-studies/element-2@2x.png";
import el from "../assets/images/case-studies/element@2x.png";
import siximg from "../assets/images/case-studies/image-19.png";
import mssql from "../assets/images/case-studies/ms-sql-vendor-product-logo-1.svg";
import grp from "../assets/images/case-studies/group-29@2x.png";
import grp2 from "../assets/images/case-studies/group-297@2x.png";
import llogo from "../assets/images/case-studies/logo-55099916ce2d70dc0844.png";
import newbi from "../assets/images/case-studies/new-power-bi-logo-svg-1.png";
import pngi from "../assets/images/case-studies/pngimg-com---vpn-png35-1.png";
import micr from "../assets/images/case-studies/microsoft-excel-logo-wine-1.svg";
import vec from "../assets/images/case-studies/vector.svg";
import './style.css';
import ScrollAnimation from '../component/scrollAnimation';
 
const ServicesPage: React.FC = () => {

    return (
        <Layout>
     <div className="case-study">
      <div className="div-2">
        <div className="overlap">
          <div className="bg">
            <div className="rectangle" />
          </div>
          <div className="text-wrapper-3">Case Study</div>
        </div>
        <div className="overlap-group">
          <div className="group">
            <img
              className="aerial-view-shanghai"
              alt="Aerial view shanghai"
              src={aerial}
            />
            <img
              className="urban-traffic-with"
              alt="Urban traffic with"
              src={urban}
            />
            <img
              className="drones-used-aerial"
              alt="Drones used aerial"
              src={drones}
            />
            <img
              className="business-person"
              alt="Business person"
              src={business}
            />
            <img
              className="cinematic-style-mall"
              alt="Cinematic style mall"
              src={cine}
            />
            <img
              className="hologram-social"
              alt="Hologram social"
              src={hologram}
            />
            <img
              className="trip-toll-highway"
              alt="Trip toll highway"
              src={trip}
            />
          </div>
          <ScrollAnimation>
          <div className="frame-2">
            <div className="overlap-group-wrapper">
              <div className="overlap-group-2">
                <img
                  className="element"
                  alt="Element"
                  src={el1}
                />
                <div className="text-wrapper-4">Brief</div>
              </div>
            </div>
            <p className="paragraph">
              Our Client operates in the toll management industry, managing multiple toll plazas under various system
              integrators. <br />
              <br />
              Efficient data management, security, and automation are crucial for their operations to <br />
              ensure seamless toll collection, <br />
              data analysis, and reporting.
            </p>
          </div>
          </ScrollAnimation>
        </div>
        <ScrollAnimation>
        <div className="overlap-2">
          <img
            className="img"
            alt="Element"
            src={el}
          />
          <div className="text-wrapper-5">Challenges</div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation>
        <img
          className="image"
          alt="Image"
          src={siximg}
        />
        </ScrollAnimation>
        <ScrollAnimation>
        <div className="overlap-3">
          <div className="ellipse" />
          <div className="ellipse-2" />
          <div className="group-2" />
          <div className="group-3" />
          <div className="group-3" />
          <div className="group-4" />
          <div className="group-5" />
          <div className="group-6" />
          <div className="MS-SQL-vendor-wrapper">
            <img
              className="MS-SQL-vendor"
              alt="Ms SQL vendor"
              src={mssql}
            />
          </div>
          <img
            className="group-7"
            alt="Group"
            src={grp}
          />
          <img
            className="group-8"
            alt="Group"
            src={grp2}
          />
          <div className="group-9" />
          <div className="logo-wrapper">
            <img
              className="logo-2"
              alt="Logo"
              src={llogo}
            />
          </div>
          <img
            className="pngimg-com-vpn"
            alt="Pngimg com vpn"
            src={pngi}
          />
          <VuesaxLinearPeople1 className="vuesax-linear-people" />
          <VuesaxLinearCloudConnection1 className="vuesax-linear-cloud" />
          <img
            className="new-power-BI-logo"
            alt="New power BI logo"
            src={newbi}
          />
          <VuesaxLinearChart className="vuesax-linear-chart" />
          <VuesaxLinearDirectSend className="vuesax-linear-direct" />
          <img
            className="microsoft-excel-logo"
            alt="Microsoft excel logo"
            src={micr}
          />
          <VuesaxLinearHierarchy1 className="vuesax-linear" />
        </div>
        </ScrollAnimation>
        <ScrollAnimation>
        <div className="overlap-wrapper">
          <div className="overlap-4">
            <img
              className="element-2"
              alt="Element"
              src={el2}
            />
            <div className="text-wrapper-6">Solution</div>
          </div>
        </div>
        <div className="frame-3">
          <div className="frame-4">
            <VuesaxBoldTickCircle className="vuesax-bold-tick" color="#FF7636" />
            <p className="p">
              <span className="span">Implemented a </span>
              <span className="text-wrapper-7">centralised data repository</span>
              <span className="span">, consolidating all data into a single, secure location.</span>
            </p>
          </div>
          <div className="frame-4">
            <VuesaxBoldTickCircle className="vuesax-bold-tick" color="#FF7636" />
            <p className="p">
              <span className="text-wrapper-7">Automated workflows</span>
              <span className="span"> using SSIS to handle data extraction from multiple plazas automatically.</span>
            </p>
          </div>
          <div className="frame-4">
            <VuesaxBoldTickCircle className="vuesax-bold-tick" color="#FF7636" />
            <p className="p">
              <span className="span">Established </span>
              <span className="text-wrapper-7">automated VPN</span>
              <span className="span"> connections to ensure secure &amp; seamless database access.</span>
            </p>
          </div>
          <div className="frame-4">
            <VuesaxBoldTickCircle className="vuesax-bold-tick" color="#FF7636" />
            <p className="p">
              <span className="span">Introduced </span>
              <span className="text-wrapper-7">real-time data integration</span>
              <span className="span"> &amp; sharing capabilities to enable seamless collaboration across teams.</span>
            </p>
          </div>
          <div className="frame-4">
            <VuesaxBoldTickCircle className="vuesax-bold-tick" color="#FF7636" />
            <p className="p">
              <span className="span">Real-time tracking and </span>
              <span className="text-wrapper-7">live data insights</span>
              <span className="span">.</span>
            </p>
          </div>
          <div className="frame-4">
            <VuesaxBoldTickCircle className="vuesax-bold-tick" color="#FF7636" />
            <p className="p">
              <span className="span">Integration with BI for </span>
              <span className="text-wrapper-7">comprehensive reporting</span>
              <span className="span"> &amp; analytics, with custom DAX measures for in-depth analysis.</span>
            </p>
          </div>
        </div>
        </ScrollAnimation>
        <div className="overlap-5">
          <div className="vuesax-bold-arrow-up-wrapper">
            <VuesaxBoldArrowUp1 className="vuesax-bold-arrow-up" />
          </div>
          <img
            className="vector"
            alt="Vector"
            src={vec}
          />
          <div className="text-wrapper-8">Outcomes</div>
        </div>
      </div>
    </div>
        </Layout>
    );
};

export default ServicesPage;