import React, { useEffect, useRef } from 'react';

const ScrollAnimation = ({ children }: any) => {
    const targetRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fade-in-right');
                    } else {
                        entry.target.classList.remove('fade-in-right');
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, []);

    return (
        <div ref={targetRef} className='flex justify-center'>
            {children}
        </div>
    );
};

export default ScrollAnimation;