// App.tsx
// COMPANY: Capitall.Io

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Product from './pages/product';
import Vdr from './pages/vdr';
import Ifc from './pages/ifc';
import DoesYourDataTalk from './pages/blogs/does-your-data-talk-you-mine';
import BusinessIntelligence from './pages/business-intelligence';
import RoleOfVdrPostCovid from './pages/blogs/role-of-vdr-post-covid';
import FixedAsset from './pages/fixed-asset';
import VendorManagement from './pages/vendor-management';
import ComplianceTool from './pages/compliance-tool';
import Offcat from './pages/offcat';
import CaseStudies from './pages/caseStudies';
import DataRoomPage from './pages/products/dataroom';
import Compliance from './pages/products/compliance';
// import BlogPage from './pages/blog';
import ContactusPage from './pages/contactus';
import NotFound from './pages/notfound';
import Team from './pages/team';
//import Career from './pages/career'
import PrivacyPage from './pages/privacy-policy';
import TermsPage from './pages/terms';
import RefundPage from './pages/refund';
import React, { useState, useEffect } from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import CapitalToll from './pages/capitall-toll';
import CapitalHrBoard from './pages/capitall-hr-board';
import CapitalDelightCafe from './pages/capitall-delight-cafe';


const App: React.FC = () => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  useEffect(() => {
    const consentCookie = document.cookie.indexOf("hannibalCookieConsent=true") !== -1;
    setIsConsentGiven(consentCookie);
  }, []);

  const handleAccept = () => {
    setIsConsentGiven(true);
    // Set the cookie to remember the user's consent
    Cookies.set("hannibalCookieConsent", "true", { expires: 150 }); 
  };

  const handleDecline = () => {
    setIsConsentGiven(true);
    Cookies.set("hannibalCookieConsent", "false", { expires: 365 }); // Store for a year
    // Disable non-essential cookies
  };


  return (
    <div className="App">
      {/* Conditionally render the banner */}
      {/* {!isConsentGiven && (
        <CookieConsent
          location="bottom"
          buttonText="I accept"
          cookieName="hannibalCookieConsent"
          style={{ background: "#172554" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
          onAccept={handleAccept}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      )} */}

      {!isConsentGiven && (
        <CookieConsent
          location="bottom"
          buttonText="Accept All"
          declineButtonText="Reject All"
          enableDeclineButton // Allow declining
          cookieName="hannibalCookieConsent"
          style={{ background: "#1f2937", color: "white", padding: "20px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)" }} // Dark background with shadow, rounded corners
          buttonStyle={{ background: "#0084ff", color: "white", padding: "10px 20px", borderRadius: "5px", margin: "10px 0" }} // Blue accept button with white text
          declineButtonStyle={{ background: "#6b7280", color: "white", padding: "10px 20px", borderRadius: "5px" }} // Gray decline button with white text
          contentStyle={{ flex: "none", maxWidth: "800px" }} // Limit content width for better readability
          expires={365} // Set cookie expiration to 1 year
          onAccept={handleAccept}
          onDecline={handleDecline}
        >
          <h3 style={{ margin: "0 0 10px 0" }}>
            We Value Your Privacy
          </h3>
          <p>
            This website uses cookies to enhance user experience, personalize content,
            and analyze website traffic. By clicking "Accept All", you consent to the use 
            of all cookies. You can manage your preferences by clicking 
            "Cookie Settings" or read our{" "}
            <a href="/privacy-policy" style={{ color: "#0084ff" }}>
              Privacy Policy
            </a>{" "}
            for more information.
          </p>
          {/* Add "Cookie Settings" button here (if needed) */}
        </CookieConsent>
      )}


      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/vdr" element={<Vdr />} />
          <Route path="/ifc" element={<Ifc />} />
          <Route path='/does-your-data-talk-you-mine' element={<DoesYourDataTalk />} />
          <Route path="/role-of-vdr-post-covid" element={<RoleOfVdrPostCovid />} />
          <Route path="/business-intelligence" element={<BusinessIntelligence />} />
          <Route path="/vendor-management" element={<VendorManagement />} />
          <Route path="/fixed-asset" element={<FixedAsset />} />
          <Route path="/compliance-tool" element={<ComplianceTool />} />
          <Route path="/office-automation" element={<Offcat />} />
          <Route path="/contact" element={<ContactusPage />} />
          {/* <Route path="/blog" element={<BlogPage />} /> */}
          <Route path="/team" element={<Team />} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
          <Route path="/terms-conditions" element={<TermsPage />} />
          <Route path="/refund-cancellations" element={<RefundPage />} />
          {/* <Route path="/career" element={<Career />} /> */}
          <Route path="/*" element={<NotFound />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/data-room" element={<DataRoomPage />} />
          <Route path='/compliance' element={<Compliance />} />
          <Route path='/capitall-toll' element={<CapitalToll />} />
          <Route path='/capitall-hr-board' element={<CapitalHrBoard />} />
          <Route path='/capitall-delight-cafe' element={<CapitalDelightCafe />} />
        </Routes>
    </Router>
    </div>
  );
};

export default App;
