/**
 * File: Notfound.tsx
 * Description: This file contains the 404 page for the Capitall application.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
                <p className="text-2xl text-gray-600 mb-8">Page not found</p>
                <Link to="/" className="text-blue-500 hover:underline">
                    Go back home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
