/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 * File: contactus.tsx
 * Description: This file contains the Contact us page component.
 */

import React, { useState, useEffect } from 'react';
import Layout from '../component/layout';
import locationicon from '../assets/brand/location.svg';
import phoneicon from '../assets/brand/call-calling.svg';
import emailicon from '../assets/brand/sms-star.svg';
import directionicon from '../assets/brand/map.png'
import { Link } from 'react-router-dom';

const ContactusPage: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth <= 768);
        });
    }, []);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contact: '',
        message: '',
    });

    const handleChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setShowSuccessMessage(false); // Reset messages
        setShowErrorMessage(false);
        // Send the form data to the server
        sendFormDataToServer(formData);
    };

    const sendFormDataToServer = (formData: any) => {
        console.log('Sending form data to server:', formData);
        // Use an HTTP library like axios or fetch to send the data to the server
        // I am using fetch here: replace the server endpoint here with your own
        // Add validation here if required

        fetch('https://hm225hf3si.execute-api.ap-south-1.amazonaws.com/prod/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from the server
                console.log('Server response:', data);
                if (data.statusCode === 200) {
                    setShowSuccessMessage(true);
                    setFormData({ // Reset form fields
                        firstName: '',
                        lastName: '',
                        email: '',
                        contact: '',
                        message: '',
                    });
                } else {
                    setShowErrorMessage(true);
                }
            })
            .catch((error) => {
                console.error('Error sending form data:', error);
                setShowErrorMessage(true);
            });
    };

    return (
        <>
            <Layout>
                <div className='contact'>
                    <div className=" pl-12 max-md:pl-5 " >
                        <div className="flex max-md:flex-col max-md:items-stretch max-md:gap-0 ">
                            <div className={`flex flex-col items-stretch w-3/5 h-3/5 max-md:w-full max-md:ml-0 ${isMobile ? 'mt-12' : 'mt-52'} `}>
                                <div className=" text-left text-white text-4xl font-semibold leading-4 ">
                                    Contact Us
                                </div>
                                <div className="flex justify-between gap-3 mt-12 items-start max-md:mt-10">
                                    <div className="text-white text-base font-medium leading-6">
                                        <div className='flex'>
                                            <img
                                                loading="lazy"
                                                src={locationicon}
                                                className="aspect-square object-contain object-center w-6 overflow-hidden mr-1"
                                                alt="location icon"
                                            />
                                            3/15, Asaf Ali Road, New Delhi-110002</div> <br />
                                        <div className='flex'>
                                            <img
                                                loading="lazy"
                                                src={phoneicon}
                                                className="aspect-square object-contain object-center w-6 overflow-hidden mr-1 "
                                                alt="phone icon"
                                            /> +91-9871098777</div> <br />{" "}

                                        <div className='flex'>
                                            <img
                                                loading="lazy"
                                                src={emailicon}
                                                className="aspect-square object-contain object-center w-6 overflow-hidden mr-1"
                                                alt="phone icon"
                                            />contactus@Capitall.io
                                        </div> <br />
                                        <div className='flex underline decoration-white  '>

                                            <img
                                                loading="lazy"
                                                src={directionicon}
                                                className="aspect-square object-contain object-center w-6 overflow-hidden mr-1"
                                                alt="phone icon"
                                            />
                                            <Link to='/location'>
                                                Get Direction</Link>
                                        </div> <br />
                                    </div>
                                </div>

                            </div>
                            <div className={`flex flex-col items-stretch w-3/5  max-md:w-11/12 max-md:m-2 m-16`}>
                                <div className=" rounded-md bg-white border-black p-6">
                                    <div className="flex flex-col my-auto max-md:max-w-full max-md:mt-10">
                                        <div className=" text-2xl font-semibold self-stretch max-md:max-w-full max-md:mr-2">
                                            Let's  <span className="">Connect</span>
                                        </div>
                                        <div className="text-zinc-500 text-sm self-stretch max-md:max-w-full max-md:mr-2">
                                            Write to us, we'll connect with you.
                                        </div>

                                        <form onSubmit={handleSubmit}>
                                            <div className="self-stretch flex items-stretch justify-between gap-3 mt-5 max-md:max-w-full max-md:flex-wrap max-md:mr-2">
                                                <div className="items-stretch flex grow basis-[0%] flex-col">
                                                    <div className="text-zinc-900 text-lg font-normal capitalize">
                                                        First Name
                                                        <span className='text-red-500 font-md ml-1'>*</span>
                                                    </div>
                                                    <div className="border flex shrink-0 h-12 flex-col mt-1.5 rounded-md border-solid border-zinc-300" >
                                                        <input
                                                            type="text"
                                                            name="firstName"
                                                            value={formData.firstName}
                                                            onChange={handleChange}
                                                            className='w-full h-full rounded-md p-4'
                                                            placeholder='First Name'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="items-stretch flex grow basis-[0%] flex-col">
                                                    <div className="text-zinc-900 text-lg font-normal capitalize">
                                                        Last Name
                                                        <span className='text-red-500 font-md ml-1'>*</span>
                                                    </div>
                                                    <div className="border bg-white flex shrink-0 h-12 flex-col mt-1.5 rounded-md border-solid border-zinc-300">
                                                        <input
                                                            type="text"
                                                            name="lastName"
                                                            value={formData.lastName}
                                                            onChange={handleChange}
                                                            className='w-full h-full rounded-md p-4'
                                                            placeholder='Last Name'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-zinc-900 text-lg font-normal capitalize self-stretch mt-5 max-md:max-w-full max-md:mr-2">
                                                Email Address
                                                <span className='text-red-500 font-md ml-1'>*</span>
                                            </div>

                                            <div className="border bg-white self-stretch flex w-full shrink-0 h-12 flex-col mt-1.5 rounded-md border-solid border-zinc-300 max-md:mr-2" >
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    className='w-full h-full rounded-md p-4'
                                                    placeholder='Email Address'
                                                />
                                            </div>
                                            <div className="text-zinc-900 text-lg font-normal capitalize self-stretch mt-5 max-md:max-w-full max-md:mr-2">
                                                Contact Number
                                                <span className='text-red-500 font-md ml-1'>*</span>
                                            </div>
                                            <div className="border bg-white self-stretch flex w-full shrink-0 h-12 flex-col mt-1.5 rounded-md border-solid border-zinc-300 max-md:mr-2" >
                                                <input
                                                    type="text"
                                                    name="contact"
                                                    value={formData.contact}
                                                    onChange={handleChange}
                                                    className='w-full h-full rounded-md p-4'
                                                    placeholder='Contact Number'
                                                />
                                            </div>
                                            <div className="text-zinc-900 text-lg font-normal capitalize self-stretch mt-5 max-md:max-w-full max-md:mr-2">
                                                Additional Information
                                                <span className='text-red-500 font-md ml-1'>*</span>
                                            </div>
                                            <div className="border bg-white self-stretch flex w-full shrink-0 h-[125px] flex-col mt-1.5 rounded-md border-solid border-zinc-300 max-md:mr-2">
                                                <textarea
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    className='w-full h-full rounded-md p-4'
                                                    placeholder='Let us hear your requirement'
                                                />
                                            </div>
                                            <button type='submit' className="text-white text-center text-base font-semibold leading-5 whitespace-nowrap justify-center items-center rounded-lg bg-blue-950 w-full max-w-full mt-6 px-16 py-2 self-start max-md:px-5">
                                                Send Message
                                            </button>
                                        </form>
                                        {/* Success Message */}
                                        {showSuccessMessage && (
                                            <div className="mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
                                                Message sent successfully!
                                            </div>
                                        )}

                                        {/* Error Message */}
                                        {showErrorMessage && (
                                            <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                                An error occurred while sending your message. Please try again.
                                            </div>
                                        )}

                                        {/* <div className="justify-center items-stretch self-stretch flex gap-3 mt-10 max-md:max-w-full max-md:flex-wrap"> */}
                                        {/* <div className="justify-between flex gap-2 items-start">
                                    <img
                                        loading="lazy"
                                        src={locationicon}
                                        alt='Location icon'
                                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                 
                                </div> */}

                                        {/* <div className="justify-center items-stretch flex gap-2 self-start">
                                    <img
                                        loading="lazy"
                                        src={emailicon}
                                        alt='Email icon'
                                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="text-stone-900 text-xs font-medium leading-6 grow whitespace-nowrap">
                                        contactus@CapItAll.io
                                    </div>
                                </div> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>


                            {/* <div className="flex flex-col items-stretch w-2/5 ml-5 max-md:w-full max-md:ml-0">
                        <div className="bg-blue-950 flex grow flex-col justify-center w-full pr-16 py-12 items-start max-md:mt-10 max-md:pr-5">
                            <div className="flex-col w-full overflow-hidden relative flex aspect-[0.8143712574850299] my-12 pl-16 pr-4 pt-12 pb-5 items-start max-md:my-10 max-md:pl-5"> */}
                            {/* <img
                                    loading="lazy"
                                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/37361b94e5d29233ed7a871d1ce330e314f2e6a980e05b26313dca5f07282b77?apiKey=33b69aca297541219314380ada42a838&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/37361b94e5d29233ed7a871d1ce330e314f2e6a980e05b26313dca5f07282b77?apiKey=33b69aca297541219314380ada42a838&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/37361b94e5d29233ed7a871d1ce330e314f2e6a980e05b26313dca5f07282b77?apiKey=33b69aca297541219314380ada42a838&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/37361b94e5d29233ed7a871d1ce330e314f2e6a980e05b26313dca5f07282b77?apiKey=33b69aca297541219314380ada42a838&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/37361b94e5d29233ed7a871d1ce330e314f2e6a980e05b26313dca5f07282b77?apiKey=33b69aca297541219314380ada42a838&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/37361b94e5d29233ed7a871d1ce330e314f2e6a980e05b26313dca5f07282b77?apiKey=33b69aca297541219314380ada42a838&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/37361b94e5d29233ed7a871d1ce330e314f2e6a980e05b26313dca5f07282b77?apiKey=33b69aca297541219314380ada42a838&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/37361b94e5d29233ed7a871d1ce330e314f2e6a980e05b26313dca5f07282b77?apiKey=33b69aca297541219314380ada42a838&"
                                    className="absolute h-full w-full object-cover object-center inset-0"
                                /> */}

                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14006.41310673021!2d77.2343021!3d28.64165!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x199783cec232784e!2sK%20G%20Somani%20%26%20Co%20LLP%20(KGS)!5e0!3m2!1sen!2sin!4v1637151495578!5m2!1sen!2sin" width="100%" height="600"
                                    title='Google map' loading="lazy" className='absolute object-cover object-center inset-0'>

                                </iframe> */}


                            {/* <div className="relative bg-white flex w-full flex-col items-stretch ml-3.5 mt-80 px-3.5 py-3 rounded max-md:ml-2.5 max-md:mt-10">
                                    <div className="flex justify-between gap-5 items-start">
                                        <div className="flex grow basis-[0%] flex-col items-stretch">
                                            <div className="text-stone-900 text-xs font-medium leading-6 whitespace-nowrap">
                                                K G Somani & Co LLP (KGS)
                                            </div>
                                            <div className="text-zinc-500 text-xs mt-2">
                                                3, 15, Asaf Ali Rd, Katra Chobey Lal, Old Delhi, New
                                                Delhi, Delhi, 110002
                                            </div>
                                        </div>
                                        <div className="justify-center items-center flex basis-[0%] flex-col mt-1">
                                            <img
                                                loading="lazy"
                                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/895b3a1796a18b273e14908f531909be8cd85365012d759d9ba5192c75a0addc?apiKey=33b69aca297541219314380ada42a838&"
                                                className="aspect-square object-contain object-center w-6 overflow-hidden max-w-full"
                                            />
                                            <div className="text-blue-950 text-xs leading-6 self-stretch whitespace-nowrap mt-1">
                                                Directions
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-stretch justify-between gap-2 mt-2.5">
                                        <div className="flex grow basis-[0%] flex-col items-stretch">
                                            <div className="text-stone-900 text-xs font-medium leading-6">
                                                4.5
                                            </div>
                                            <div className="text-blue-950 text-xs font-medium leading-6 underline mt-5">
                                                View Larger Map
                                            </div>
                                        </div>
                                        <div className="text-blue-950 text-xs font-semibold leading-6">
                                            46 reviews
                                        </div>
                                    </div>
                                </div> */}
                            {/* </div>
                        </div>
                    </div> */}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default ContactusPage;