/**
 * File: Vdr.tsx
 * Description: This file contains the implementation of the Product component.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import Layout from '../component/layout';

//Image imports
import producttopimg from '../assets/images/compliance.jpg';
import tickicon from '../assets/images/product/tick-icon.svg';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../component/scrollAnimation';

const ComplianceTool: React.FC = () => {
    // Component implementation goes here
    return (
        <Layout>
            <div className="bg-white flex flex-col">
                <div className="flex items-stretch gap-1.5 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-0">
                        <div className="items-stretch flex justify-between gap-2 pr-2">
                            <div className="text-orange-400 text-sm font-medium leading-5">
                                <Link to='/'> Home</Link>
                            </div>
                            <div className="text-orange-400 text-sm leading-5">&gt;</div>
                        </div>
                        <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                            <Link to='/product'>  Products </Link>
                        </div>
                    </div>
                    <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Compliance tool
                    </div>
                </div>
                <div className="self-center w-full max-w-[1597px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col mt-8 items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-black text-3xl font-semibold self-stretch max-md:max-w-full">
                                    <b>Compliance Management Software:</b> What It Is and Why You Need It
                                    <br />
                                    <br />
                                </div>
                                <div className="text-zinc-500 text-justify text-lg self-stretch mt-8 max-md:max-w-full">
                                Compliance management software is a tool that helps organizations comply with internal policies, regulatory and legal requirements, and industry standards. 
                                It can automate compliance-related tasks and workflows, address risk management issues, and assure that your policies and procedures follow the applicable laws and regulatory requirements. 
                                </div>
                                {/*<img
                                    loading="lazy"
                                    src={chaticon}
                                    alt='chat-icon'
                                    className="aspect-square object-contain object-center w-[101px] shadow-lg overflow-hidden max-w-full mr-10 mt-24 max-md:mr-2.5 max-md:mt-10"
                                />*/}
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[56%] max-md:w-full ml-10 max-md:ml-0">
                            <img
                                loading="lazy"
                                src={producttopimg}
                                srcSet={`${producttopimg} 1x, ${producttopimg} 2x , ${producttopimg} 4x, ${producttopimg} 8x, ${producttopimg} 12x, ${producttopimg} 16x, ${producttopimg} 20x`}
                                alt='product-top-img'
                                className="aspect-[1.69] object-contain object-center w-full overflow-hidden max-w-[707px]"
                            />
                        </div>
                    </div>
                </div>
                <ScrollAnimation>
                <div className="self-center flex w-full max-w-[1527px] flex-col mt-24 px-5 max-md:max-w-full max-md:mt-10">
                    <div className="text-blue-950 text-3xl font-semibold self-start max-md:max-w-full m-auto">
                        Benefits of using A Compliance tool
                    </div>
                    <div className="text-zinc-500 text-center text-xl mt-4 self-start max-md:max-w-full m-auto">
                        Here are some of the benefits of using compliance management software: 
                    </div>
                    <div className="self-stretch mt-24 max-md:max-w-full max-md:mt-10 mb-20">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-5 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Increased compliance:{" "}
                                        <span className=" leading-6">
                                        Compliance management software can help you stay up-to-date on the latest regulations and ensure that your organization is in compliance with all applicable laws and standards. This can help you avoid costly fines and penalties, as well as protect your organization from reputational damage.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Reduced risk:{" "}
                                        <span className=" leading-6">
                                        Compliance management software can help you identify and mitigate risks to your organization's compliance. This can help you avoid costly incidents and ensure that your organization is operating in a safe and secure manner. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto mt-1">
                                        Improved efficiency:{" "}
                                        <span className=" leading-6">
                                        Compliance management software can automate many of the manual tasks involved in compliance, such as tracking regulatory changes, managing risk assessments, and generating reports. This can free up your employees to focus on more strategic activities, and it can help you save time and money. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[1007px] max-w-full self-start max-md:mt-10 m-auto">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-7 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-7 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Improved visibility:{" "}
                                        <span className=" leading-6">
                                        Compliance management software can provide you with a centralized view of your organization's compliance status. This can help you identify areas where your organization is at risk of non-compliance, and it can help you track your progress towards compliance goals. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-zinc-500 text-left text-xl mt-14 self-start max-md:max-w-full m-auto">
                    <ul className='list-disc'>
                    <li>If your organization is subject to any regulations or standards, then you should consider using compliance management software. It can help you improve your compliance posture, reduce your risk, and improve your efficiency.</li>
                    <li>If you are looking for a comprehensive and easy-to-use fixed asset management tool, then our tool is the perfect solution for you. Contact us today to learn more about how our tool can help you to improve your asset management processes.</li>
                    </ul>
                    </div>
                    <div className="text-zinc-500 text-left text-xl mt-4 self-start max-md:max-w-full m-auto">
                    <div className="text-blue-950 text-2xl mb-5 mt-5 font-semibold self-stretch max-md:max-w-full">
                                    Here are some of the features to look for in compliance management software:
                                    
                                </div>
                        <ul className='list-disc'>
                            <li>Regulatory compliance: The software should cover all of the regulations and standards that your organization is subject to.</li>
                            <li>Risk management: The software should help you identify and mitigate risks to your organization's compliance.</li>
                            <li>Workflow automation: The software should automate many of the manual tasks involved in compliance, such as tracking regulatory changes, managing risk assessments, and generating reports.</li>
                            <li>Reporting: The software should provide you with reports on your organization's compliance status.</li>
                            <li>User-friendly interface: The software should be easy to use for all levels of employees.</li>
                        </ul>
                    </div>
                    <div className="text-zinc-500 text-left text-xl mt-4 self-start max-md:max-w-full mb-10 m-auto">
                    If you are looking for compliance management software, I recommend that you do your research and compare different products before making a decision. There are many different compliance management software solutions on the market, and the best solution for your organization will depend on your specific needs and requirements. 
                    </div>
                </div>
                </ScrollAnimation>
                <div className="bg-blue-950 self-stretch flex w-full flex-col items-center mt-2 px-20 py-12 max-md:max-w-full max-md:px-5">
                    <div className="text-white text-center text-4xl font-semibold leading-5 mt-6 max-md:max-w-full m-text-lh">
                        Overview about Compliance tool
                    </div>
                    <Link to="/contact">
                    <div className="text-white text-center text-base font-semibold leading-5 whitespace-nowrap justify-center items-center rounded bg-orange-400 w-[237px] max-w-full mt-11 mb-1.5 px-16 py-4 max-md:mt-10 max-md:px-5">
                        Get Quotation
                    </div>
                    </Link>
                </div>
               

            </div>
        </Layout>
    );
};

export default ComplianceTool;
