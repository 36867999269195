/**
 * File: layout.tsx
 * Description: This file contains the layout component for the Capitall application.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React, { ReactNode } from 'react';
import Header from './header';
import Footer from './footer';

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div>
            <Header />
            <main>{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;