/**
 * File: Vdr.tsx
 * Description: This file contains the implementation of the Product component.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import Layout from '../component/layout';

//Image imports
import producttopimg from '../assets/images/product/product-top-img.webp';
import tickicon from '../assets/images/product/tick-icon.svg';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../component/scrollAnimation';

const Offcat: React.FC = () => {
    // Component implementation goes here
    return (
        <Layout>
            <div className="bg-white flex flex-col">
                <div className="flex items-stretch gap-1.5 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-0">
                        <div className="items-stretch flex justify-between gap-2 pr-2">
                            <div className="text-orange-400 text-sm font-medium leading-5">
                                <Link to='/'> Home</Link>
                            </div>
                            <div className="text-orange-400 text-sm leading-5">&gt;</div>
                        </div>
                        <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                            <Link to='/product'>  Products </Link>
                        </div>
                    </div>
                    <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Office automation tool
                    </div>
                </div>
                <div className="self-center w-full max-w-[1597px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[56%] max-md:w-full max-md:ml-0">
                            <img
                                loading="lazy"
                                src={producttopimg}
                                srcSet={`${producttopimg} 1x, ${producttopimg} 2x , ${producttopimg} 4x, ${producttopimg} 8x, ${producttopimg} 12x, ${producttopimg} 16x, ${producttopimg} 20x`}
                                alt='product-top-img'
                                className="aspect-[1.69] object-contain object-center w-full overflow-hidden max-w-[707px]"
                            />
                        </div>
                        <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col mt-8 items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-black text-3xl font-semibold self-stretch max-md:max-w-full">
                                    Office automation tool
                                    <br />
                                    <br />
                                </div>
                                <div className="text-zinc-500 text-justify text-lg self-stretch mt-8 max-md:max-w-full">
                                In today's fast-paced and ever-changing business world, 
                                it is more important than ever for businesses to find ways to streamline their operations and improve efficiency. One of the best ways to do this is to invest in office automation tools.
                                <br /><br />
                                Office automation tools are software applications that can automate a variety of tasks, 
                                from data entry and document processing to customer service and project management. 
                                By automating these tasks, businesses can free up their employees to focus on more strategic and value-added activities. 
                                </div>
                                {/*<img
                                    loading="lazy"
                                    src={chaticon}
                                    alt='chat-icon'
                                    className="aspect-square object-contain object-center w-[101px] shadow-lg overflow-hidden max-w-full mr-10 mt-24 max-md:mr-2.5 max-md:mt-10"
                                />*/}
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollAnimation>
                <div className="self-center flex w-full max-w-[1527px] flex-col mt-24 px-5 max-md:max-w-full max-md:mt-10">
                    <div className="text-blue-950 text-3xl font-semibold self-start max-md:max-w-full m-auto">
                        Benefits of using A Office automation tool
                    </div>
                    <div className="text-zinc-500 text-center text-xl mt-4 self-start max-md:max-w-full m-auto">
                        There are many benefits to using a Office automation tool, including:
                    </div>
                    <div className="self-stretch mt-24 max-md:max-w-full max-md:mt-10 mb-20">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-5 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Increased efficiency:{" "}
                                        <span className=" leading-6">
                                        Office automation tools can help businesses to automate repetitive tasks, which can free up employees to focus on more important work. This can lead to significant time savings and increased productivity.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Improved accuracy:{" "}
                                        <span className=" leading-6">
                                        Office automation tools can help to reduce errors in data entry and document processing. This can help to improve the quality of work and avoid costly mistakes. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto mt-1">
                                        Enhanced collaboration:{" "}
                                        <span className=" leading-6">
                                        Office automation tools can help to improve communication and collaboration between employees. This can lead to better decision-making and a more cohesive work environment. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[1007px] max-w-full mb-20 self-start max-md:mt-10 m-auto">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-7 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-7 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Reduced costs:{" "}
                                        <span className=" leading-6">
                                        Office automation tools can help to reduce costs by automating tasks that would otherwise be performed by employees. This can free up budget for other areas of the business. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-zinc-500 text-left text-xl mt-4 self-start max-md:max-w-full m-auto">
                    If you are looking for ways to improve the efficiency and productivity of your business, then office automation tools are a great option. There are many different office automation tools available, so you can choose the ones that are right for your specific needs. 
                    </div>
                </div>
                </ScrollAnimation>
                <ScrollAnimation>
                <div className="self-center w-full max-w-[1597px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col mt-8 items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-blue-950 text-2xl font-semibold self-stretch max-md:max-w-full">
                                    Here are some of the features Office automation tools we offer
                                    <br />
                                    <br />
                                </div>
                                <div className="text-zinc-500 text-justify text-lg self-stretch max-md:max-w-full">
                                <ul className='list-disc'>
                                    <li>Data entry automation software: This software can automate the process of entering data into spreadsheets and databases. This can save hours of time and reduce errors.</li>
                                    <li>Document processing software: This software can automate the process of creating, editing, and managing documents. This can save time and improve the accuracy of document processing.</li>
                                    <li>Customer service software: This software can automate the process of handling customer inquiries and complaints. This can free up customer service representatives to focus on more complex issues.</li>
                                    <li>Project management software: This software can help you to plan, track, and manage projects. This can help to improve efficiency and avoid costly delays.</li>
                                    <li>We are confident that our office automation tools can help you to improve the efficiency and productivity of your business. Contact us today to learn more about how we can help you. </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[56%] max-md:w-full ml-10 max-md:ml-0">
                            <img
                                loading="lazy"
                                src={producttopimg}
                                srcSet={`${producttopimg} 1x, ${producttopimg} 2x , ${producttopimg} 4x, ${producttopimg} 8x, ${producttopimg} 12x, ${producttopimg} 16x, ${producttopimg} 20x`}
                                alt='product-top-img'
                                className="aspect-[1.69] object-contain object-center w-full overflow-hidden max-w-[707px]"
                            />
                        </div>
                    </div>
                    <div className="text-zinc-500 text-left text-xl mt-4 self-start max-md:max-w-full mb-10 m-auto">
                    To learn more about our office automation tools, please visit our website or contact us today. We would be happy to answer any questions you have and help you to choose the right tools for your business. 
                    </div>
                </div>
                </ScrollAnimation>
                <div className="bg-blue-950 self-stretch flex w-full flex-col items-center mt-2 px-20 py-12 max-md:max-w-full max-md:px-5">
                    <div className="text-white text-center text-4xl font-semibold leading-5 mt-6 max-md:max-w-full m-text-lh">
                        Overview about Office automation tool
                    </div>
                    <Link to="/contact">
                    <div className="text-white text-center text-base font-semibold leading-5 whitespace-nowrap justify-center items-center rounded bg-orange-400 w-[237px] max-w-full mt-11 mb-1.5 px-16 py-4 max-md:mt-10 max-md:px-5">
                        Get Quotation
                    </div>
                    </Link>
                </div>
                
               

            </div>
        </Layout>
    );
};

export default Offcat;
