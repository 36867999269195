/**
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.io
 * File path: src/pages/home.tsx
 * File Description: This file contains the home page component.
 */

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../component/layout';
//Image imports
import hpwhitepattern from '../assets/images/homepage/hp-white-pattern.svg'
import hpuserimg from '../assets/images/homepage/hp-user.png';
import interfaceicon from '../assets/images/homepage/interface-icon.svg';
import integrationicon from '../assets/images/homepage/integration-icon.svg';
import analyticsicon from '../assets/images/homepage/analytics-icon.svg';
import managementicon from '../assets/images/homepage/management-icon.svg';
import automationicon from '../assets/images/homepage/automation-icon.svg';
import hpteamimg from '../assets/images/homepage/hp-team-img.webp';
import hptopimage1 from '../assets/images/homepage/action-tracker-tool.jpeg';
import hptopimage2 from '../assets/images/homepage/bi.jpeg';
import hptopimage3 from '../assets/images/homepage/FixAssetModule.jpg';
import hptopimage4 from '../assets/images/homepage/virtualData.png';
import hptopimage5 from '../assets/images/homepage/OATool1.jpg';
import hptopimage6 from '../assets/images/homepage/ifc-tool.jpg';

import vdricon from '../assets/images/vdr.jpg';
import financial from '../assets/images/ifc.png';
import ofcauto from '../assets/images/oatool.jpg';
import compliance from '../assets/images/compliance.jpg';
import asticon from '../assets/images/FixAssetModule.jpg';
import vendoricon from '../assets/images/vm.jpg';

import Carousel from '../component/carousel';
import ScrollAnimation from '../component/scrollAnimation';
import { Link } from 'react-router-dom';

interface ImageProps {
  src: any;
  alt: string;
}

const Home: React.FC = () => {
  //const images = [hpcarouselimg1, hpcarouselimg2, hpcarouselimg3];
  const images = [hptopimage1, hptopimage2, hptopimage3, hptopimage4, hptopimage5, hptopimage6];
  const hptopimages: ImageProps[] = [
    { src: hptopimage1, alt: 'Image 1' },
    { src: hptopimage2, alt: 'Image 2' },
    { src: hptopimage3, alt: 'Image 3' },
    { src: hptopimage4, alt: 'Image 4' },
    { src: hptopimage5, alt: 'Image 5' },
    { src: hptopimage6, alt: 'Image 6' }
  ];


  const [centerIndex, setCenterIndex] = useState<number>(0);

  const handleImageClick = (index: number) => {
    setCenterIndex(index);
  };

  const visibleImages: ImageProps[] = [
    hptopimages[(centerIndex - 1 + images.length) % images.length],
    hptopimages[centerIndex],
    hptopimages[(centerIndex + 1) % images.length],
  ];
  const [activeTestimonialContent, setActiveTestimonialContent] = useState<number>(1);
  const [autoPlay, setAutoPlay] = useState<boolean>(true);

  const handleCircleClick = (contentNumber: number) => {
    setAutoPlay(false); // Turn off auto play when manually selecting a circle
    setActiveTestimonialContent(contentNumber);
  };

  // Auto mode: Switch content every 3 seconds
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (autoPlay) {
      intervalId = setInterval(() => {
        setActiveTestimonialContent((prevContent) => (prevContent % 2) + 1);
      }, 5000);
    } return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoPlay]);

  return (
    <Layout>
      <div className='m-hp-top-section ease-in duration-300'>
        <Helmet>
          <title>Home Page - capitall.io</title>
          <meta name="description" content="Welcome to our website. Learn about our services and more." />
        </Helmet>

        <div className="bg-white flex flex-col items-stretch">
          <div className="bg-blue-300 flex flex-col justify-center items-stretch">
            <img
              loading="lazy"
              src={hpwhitepattern}
              alt='white pattern'
              className="aspect-[2.15] object-contain object-center w-full mix-blend-luminosity overflow-hidden mt-1.5 mb-1 max-md:max-w-full m-display-none"
            />
            <div className="hp-top-banner absolute z-10 self-center flex w-full max-w-[1482px] flex-col items-stretch  px-px max-md:max-w-full max-md:mt-10">
              <div className="max-md:max-w-full">
                <div className="gap-16 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                  <div className="flex max-w-[625px] flex-col px-5 items-start">
                    <div className="text-blue-950 text-5xl font-semibold leading-[66px] self-stretch w-full max-md:max-w-full max-md:text-4xl max-md:leading-[59px]">
                      Manage, share, prepare and communicate with the teams and external members.
                    </div>
                    <div className="self-stretch flex items-stretch justify-between gap-4 mt-11 max-md:max-w-full max-md:flex-wrap max-md:mt-10">
                      <img
                        loading="lazy"
                        src={hpuserimg}
                        srcSet={`${hpuserimg} 1x, ${hpuserimg} 2x , ${hpuserimg} 4x, ${hpuserimg} 8x, ${hpuserimg} 12x, ${hpuserimg} 16x, ${hpuserimg} 20x`}
                        alt='hp user'
                        className="aspect-[2.26] object-contain object-center w-[120px] overflow-hidden shrink-0 max-w-full"
                      />
                      <div className="text-balance text-zinc-900 text-lg leading-7 grow shrink basis-auto mt-1.5 self-start max-md:max-w-full">
                        <span className="">One Stop</span>{" "}
                        <span className="font-bold leading-6 text-zinc-900">
                          Solution
                        </span>{" "}
                        <span className="">for all your needs.</span>
                      </div>
                    </div>
                    <Link to='./product'>
                    <button className="transition delay-150 duration-300 ease-in-out text-white text-center text-lg font-bold leading-6 whitespace-nowrap justify-center items-center bg-orange-400 w-[255px] max-w-full mt-28 px-16 py-4 rounded-xl max-md:mt-10 max-md:px-5">
                    Show Products
                    </button></Link>
                  </div>
                  <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                    <Carousel images={images} autoPlay={true} showNextPrevButtons={false} />
                  </div>
                </div>
              </div>
              {/*<img
                loading="lazy"
                src={chaticon}
                alt='chat icon'
                className="aspect-square object-contain object-center w-[101px] shadow-lg overflow-hidden max-w-full mr-8 mt-20 self-end max-md:mr-2.5 max-md:mt-10"
                  />*/}
            </div>
          </div>


          {/* <img
            loading="lazy"
            src={hpcenterimg}
            srcSet={`${hpcenterimg} 1x, ${hpcenterimg} 2x , ${hpcenterimg} 4x, ${hpcenterimg} 8x, ${hpcenterimg} 12x, ${hpcenterimg} 16x, ${hpcenterimg} 20x`}
            alt='hp center img'
            className="aspect-[2.69] object-contain object-center w-full shadow-lg overflow-hidden self-center max-w-[1528px] mt-20 max-md:max-w-full max-md:mt-10"
          />          <div className="image-gallery p-16">
            {visibleImages.map((image, index) => (
              <img
                key={image.src}
                src={image.src}
                alt={image.alt}
                onClick={() => handleImageClick((centerIndex - 1 + index + images.length) % images.length)}
                className={`${index === 1 ? 'center' : 'clickable'}  
                  aspect-[1] cursor-pointer  w-full shadow-lg overflow-hidden self-center max-w-[600px] max-h-[400px] mt-20 max-md:max-w-full max-md:mt-10`}
              />
            ))}
            </div>*/}


          
            <div className="text-blue-950 text-center text-4xl font-bold leading-7 self-center mt-11 max-md:max-w-full max-md:mt-10">
              Workflow Automation
            </div>
            <ScrollAnimation>
            <div className="text-zinc-600 text-center text-xl font-medium self-center w-[1100px] mt-6 max-md:max-w-full">
              Office automation tools are software applications that can automate a
              variety of tasks, from data entry and document processing to customer
              service and project management. By automating these tasks, businesses
              can free up their employees to focus on more strategic and value-added
              activities.
            </div>
            </ScrollAnimation>
            <ScrollAnimation>
          <div className="bg-red-50 flex w-full flex-col items-stretch mt-16 pt-2.5 max-md:max-w-full max-md:mt-10">

            <div className='self-center w-full max-w-[1254px] mt-20 mb-14 max-md:max-w-full max-md:my-10'>
              <div className="text-blue-950 text-center text-5xl font-bold leading-10 max-md:max-w-full">
                Explore CapItAll
              </div>
              <div className="text-zinc-600 text-center text-xl font-medium leading-7 max-md:max-w-full mt-5">
                CapItAll is a one stop platform for all your needs including but not limited to Project Management, Workflow Automation, Compliance, Audit Management, documentation generation using AI etc.
              </div>
              <div className="self-stretch w-full mt-20 px-5 max-md:max-w-full max-md:mt-10">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                  <div className="flex flex-col items-stretch w-[32%] max-md:w-full max-md:ml-0">
                    <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                      <img
                        loading="lazy"
                        src={interfaceicon}
                        alt='Interface'
                        className="aspect-[0.95] object-contain object-center w-[42px] overflow-hidden self-center max-w-full"
                      />
                      <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-8">
                        Interface
                      </div>
                      <div className="text-zinc-600 text-center text-base leading-7 mt-8">
                        CapItAll addresses key data custody challenges for corporations
                        and consultants. Their data room solution unifies scattered data
                        among internal/external stakeholders.
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-stretch w-[35%] ml-5 max-md:w-full max-md:ml-0">
                    <div className="justify-center items-stretch flex grow flex-col max-md:mt-6">
                      <img
                        loading="lazy"
                        src={interfaceicon}
                        alt='Agile'
                        className="aspect-[0.95] object-contain object-center w-[42px] overflow-hidden self-center max-w-full"
                      />
                      <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-8">
                        Agile
                      </div>
                      <div className="text-zinc-600 text-center text-base leading-7 mt-8">
                        The agility and the versitality of the platform enables the
                        corporates to bring in other routine functions such as managing
                        risks, controls, workflows, assets, compliances, vendors,
                        customers.
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                    <div className="justify-center items-stretch flex grow flex-col mt-1 max-md:mt-7">
                      <img
                        loading="lazy"
                        src={analyticsicon}
                        alt='Data Analytics'
                        className="aspect-[1.05] object-contain object-center w-[42px] overflow-hidden self-center max-w-full"
                      />
                      <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-8">
                        Data Analytics
                      </div>
                      <div className="text-zinc-600 text-center text-base leading-7 mt-8">
                        CapItAll is not just a repository of corporate information.
                        Files and information once pushed in can be and should be
                        converted into data points to make decision making more precise
                        and accurate.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="relative self-center w-full max-w-[1254px] mt-6 mb-20 max-md:max-w-full max-md:my-10">
              <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                  <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                    <img
                      loading="lazy"
                      src={integrationicon}
                      alt='Integrations'
                      className="aspect-[0.93] object-contain object-center w-[38px] overflow-hidden self-center max-w-full"
                    />
                    <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-8">
                      Integrations
                    </div>
                    <div className="text-zinc-600 text-center text-base leading-7 mt-8">
                      Robust integrations allow you to bring in other tools and
                      platforms to reduce fragmentation of tools and elevate the
                      convenience of operations. Integrations with ERP/ other
                      functional tools bring it all under one roof.
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-stretch w-[36%] ml-5 max-md:w-full max-md:ml-0">
                  <div className="justify-center items-stretch relative flex grow flex-col max-md:max-w-full max-md:mt-6">
                    <img
                      loading="lazy"
                      src={automationicon}
                      alt='Automations and Tools'
                      className="aspect-[1.05] object-contain object-center w-[42px] overflow-hidden self-center max-w-full"
                    />
                    <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-8">
                      Automations and Tools
                    </div>
                    <div className="text-zinc-600 text-center text-base leading-7 mt-8 max-md:max-w-full">
                      Our specialized tools for finance and operations bring in
                      efficiency of cost, time and quality. Additionally, CapItAll
                      lives on the ethos of automation. Automation brings
                      transparency, reliability and ultimately better corporate
                      governance.
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-stretch w-[31%] ml-5 max-md:w-full max-md:ml-0">
                  <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                    <img
                      loading="lazy"
                      src={managementicon}
                      alt='Organisation Management'
                      className="aspect-[0.95] object-contain object-center w-10 overflow-hidden self-center max-w-full"
                    />
                    <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-8">
                      Organisation Management
                    </div>
                    <div className="text-zinc-600 text-center text-base leading-7 mt-8">
                      Make company more organised and a better place to operate.
                      Be it work from home or from office, once documented, the
                      responsibility and accountability is established.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation>
            <div className="flex w-full flex-col items-stretch mt-16 pt-2.5 max-md:max-w-full max-md:mt-10">

            <div className='self-center w-full max-w-[1254px] mt-20 mb-14 max-md:max-w-full max-md:my-10'>
              <div className="text-blue-950 text-5xl text-center font-bold leading-10 max-md:max-w-full">
                What We Offer
              </div>
              
              <div className="self-stretch w-full px-5 max-md:max-w-full max-md:mt-10">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[32%] max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch flex flex-col max-md:mt-6">
                            <img
                                loading="lazy"
                                src={vdricon}
                                alt='vdr'
                                className="aspect-[0.95] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                            <Link to='/vdr'>Virtual Data Room</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7">
                            A virtual data room (VDR) is a secure online repository for storing and sharing confidential documents.
                            </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[35%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch flex grow flex-col max-md:mt-6">
                            <img
                                loading="lazy"
                                src={financial}
                                alt='financial'
                                className="aspect-[0.95] object-contain object-center w-[290px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                            <Link to="/ifc">Financial Control Tool</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7">
                            Our Internal Financial Control Tool is easy to use and scalable, making it the perfect solution.
                            </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch flex grow flex-col mt-1 max-md:mt-7">
                            <img
                                loading="lazy"
                                src={ofcauto}
                                alt='ofc-auto'
                                className="aspect-[1.05] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                            <Link to="/office-automation">Office Automation</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7">
                            Office automation tools are software applications that can automate a variety of tasks. 
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

            </div>
            <div className="relative self-center w-full max-w-[1254px] mt-6 mb-20 max-md:max-w-full max-md:my-10">
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                            <img
                            loading="lazy"
                            src={compliance}
                            alt='compliance'
                            className="aspect-[0.93] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                            <Link to="/compliance-tool">Compliance Tool</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7">
                            Compliance management software is a tool that helps organizations comply with internal policies.
                            </div>
                        </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[36%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch relative flex grow flex-col max-md:max-w-full max-md:mt-6">
                            <img
                            loading="lazy"
                            src={asticon}
                            alt='ast-mgmt'
                            className="aspect-[1.05] object-contain object-center w-[330px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-1">
                            <Link to="/fixed-asset">Asset Management</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7 max-md:max-w-full mt-1">
                            Our tool is a comprehensive solution for tracking all of your fixed assets. It allows you to easily create and manage asset.
                            </div>
                        </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[31%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                            <img
                            loading="lazy"
                            src={vendoricon}
                            alt='vendor'
                            className="aspect-[0.95] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                            />
                            <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap mt-1">
                            <Link to="/vendor-management">Vendor Management</Link>
                            </div>
                            <div className="text-zinc-600 text-center text-base leading-7 mt-1">
                            Our vendor management tool provides a central repository for all of your vendor information.
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                                <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                &nbsp;
                                </div>
                                <div className="text-zinc-600 text-center text-base leading-7">
                                &nbsp;
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                                <img
                                loading="lazy"
                                src={compliance}
                                alt='compliance'
                                className="aspect-[0.93] object-contain object-center w-[300px] overflow-hidden self-center max-w-full"
                                />
                                <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                <Link to="/business-intelligence">Business Intelligence Services</Link>
                                </div>
                                <div className="text-zinc-600 text-center text-base leading-7">
                                &nbsp;
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                            <div className="justify-center items-stretch relative flex flex-col max-md:mt-6">
                                <div className="text-blue-950 text-center text-xl font-semibold leading-7 self-center whitespace-nowrap">
                                &nbsp;
                                </div>
                                <div className="text-zinc-600 text-center text-base leading-7">
                                &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            {/*}<div className="absolute flex-col mix-blend-soft-light overflow-hidden  z-[1] flex min-h-[1038px] mb-0 w-full px-20 py-12 items-start max-md:max-w-full max-md:px-5">
              <img
                loading="lazy"
                src={hpcenterbgimg}
                srcSet={`${hpcenterbgimg} 1x, ${hpcenterbgimg} 2x , ${hpcenterbgimg} 4x, ${hpcenterbgimg} 8x, ${hpcenterbgimg} 12x, ${hpcenterbgimg} 16x, ${hpcenterbgimg} 20x`}
                alt='hp center bg img'
                className="absolute h-full w-full object-cover object-center inset-0"
              />
          </div>{*/}
          </div>
          </ScrollAnimation>
          <ScrollAnimation>
          <div className="self-center w-full max-w-[1489px] max-md:max-w-full max-md:mt-10">
            <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
              <div className="flex flex-col items-stretch w-[46%] max-md:w-full max-md:ml-0">
                <div className="flex flex-col my-auto px-5 items-start max-md:max-w-full max-md:mt-10">
                  <div className="text-black text-5xl font-bold self-stretch max-md:max-w-full">
                    Build & Manage Teams
                  </div>
                  <div className="text-slate-500 text-xl font-medium leading-8 self-stretch mt-9 max-md:max-w-full">
                    The most powerful finance software that connects with your
                    financial accounts. Meet the team behind this magic.
                  </div>
                  <div className="text-white cursor-pointer text-center text-base font-semibold leading-5 whitespace-nowrap justify-center items-stretch rounded bg-orange-400 mt-16 px-16 py-4 max-md:mt-10 max-md:px-5">
                    <Link to={'/team'}>
                    Know Our Team</Link>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-stretch w-[54%] ml-5 max-md:w-full max-md:ml-0">
                <img
                  loading="lazy"
                  src={hpteamimg}
                  srcSet={`${hpteamimg} 1x, ${hpteamimg} 2x , ${hpteamimg} 4x, ${hpteamimg} 8x, ${hpteamimg} 12x, ${hpteamimg} 16x, ${hpteamimg} 20x`}
                  alt='hp team img'
                  className="aspect-[1.43] object-contain object-center w-full overflow-hidden grow max-md:max-w-full max-md:mt-10"
                />
              </div>
            </div>
          </div>
          </ScrollAnimation>
          {/*<div className="bg-white flex w-full flex-col items-stretch mt-20 pl-20 py-12 max-md:max-w-full max-md:mt-10 max-md:pl-5">
            <div className="text-left text-gray-950  text-5xl font-semibold leading-[61.88px] tracking-tighter ml-8  max-md:max-w-full max-md:text-4xl max-md:mt-10">
              Read our blog
            </div>
            <div className="ml-8 mt-16 max-md:max-w-full max-md:mt-10">
              <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                <div className="flex flex-col items-stretch w-[39%] max-md:w-full max-md:ml-0">
                  <img
                    loading="lazy"
                    src={hpbottomsectionblogimg}
                    srcSet={`${hpbottomsectionblogimg} 1x, ${hpbottomsectionblogimg} 2x , ${hpbottomsectionblogimg} 4x, ${hpbottomsectionblogimg} 8x, ${hpbottomsectionblogimg} 12x, ${hpbottomsectionblogimg} 16x, ${hpbottomsectionblogimg} 20x`}
                    alt='bottom section blog img 1'
                    className="aspect-[1.95] object-contain object-center w-full overflow-hidden grow max-md:max-w-full max-md:mt-6"
                  />
                </div>
                <div className="flex flex-col items-stretch w-[39%] ml-5 max-md:w-full max-md:ml-0">
                  <img
                    loading="lazy"
                    src={hpbottomsectionblogimg}
                    srcSet={`${hpbottomsectionblogimg} 1x, ${hpbottomsectionblogimg} 2x , ${hpbottomsectionblogimg} 4x, ${hpbottomsectionblogimg} 8x, ${hpbottomsectionblogimg} 12x, ${hpbottomsectionblogimg} 16x, ${hpbottomsectionblogimg} 20x`}
                    alt='bottom section blog img 2'
                    className="aspect-[1.95] object-contain object-center w-full overflow-hidden grow max-md:max-w-full max-md:mt-6"
                  />
                </div>
                <div className="flex flex-col items-stretch w-[23%] ml-5 max-md:w-full max-md:ml-0">
                  <img
                    loading="lazy"
                    src={hpbottomsectionbloghalfimg}
                    srcSet={`${hpbottomsectionbloghalfimg} 1x, ${hpbottomsectionbloghalfimg} 2x , ${hpbottomsectionbloghalfimg} 4x, ${hpbottomsectionbloghalfimg} 8x, ${hpbottomsectionbloghalfimg} 12x, ${hpbottomsectionbloghalfimg} 16x, ${hpbottomsectionbloghalfimg} 20x`}
                    alt='bottom section blog half img'
                    className="aspect-[1.15] object-contain object-center w-[358px] overflow-hidden shrink-0 max-w-full grow max-md:mt-6"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-stretch justify-between gap-5 ml-8 mt-7 mb-12 max-md:max-w-full max-md:flex-wrap max-md:mb-10">
              <div className="w-[40%] text-gray-950 text-2xl font-semibold leading-8 tracking-tight grow shrink basis-auto max-md:max-w-full">
                Most popular design systems to learn from in 2022
              </div>
              <div className="w-[40%] text-gray-950 text-2xl font-semibold leading-8 tracking-tight grow shrink basis-auto max-md:max-w-full">
                Most popular design systems to learn from in 2022
              </div>
              <div className="w-[22%] text-gray-950 text-2xl font-semibold leading-8 tracking-tight grow shrink basis-auto">
                Most popular design systems to learn from in 2022
              </div>
            </div>
          </div>*/}
          <div className="transition ease-in duration-300">
            {activeTestimonialContent === 1 && <div className="justify-center items-center bg-blue-950 flex w-full flex-col  px-20 py-12 max-md:max-w-full max-md:mt-10 max-md:px-5">
              <div className="text-white text-center text-2xl font-medium leading-[40px] tracking-tighter w-[1216px] mt-5 max-md:max-w-full max-md:text-4xl max-md:leading-[56px]">
              I would once again like to thank you and your entire team for all the support extended in the preparation of the due diligence readiness for the Transaction.

You and your team worked like true partners and provided the ground level support to manage the entire transaction diligence. I would like to make a special mention of the data room repository which was created to support the process – immaculate system in place to manage the entire data flow with ease.

Thanks again.
              </div>
              {/*<img
                loading="lazy"
                src={ladyicon}
                srcSet={`${ladyicon} 1x, ${ladyicon} 2x , ${ladyicon} 4x, ${ladyicon} 8x, ${ladyicon} 12x, ${ladyicon} 16x, ${ladyicon} 20x`}
                alt='lady icon'
                className="aspect-square object-contain object-center w-16 justify-center items-center overflow-hidden max-w-full mt-8"
          />*/}
              <div className="text-white text-center text-lg font-medium leading-7 mt-4 max-md:max-w-full">
                Balaji Thotadri
              </div>
              <div className="text-white text-center text-base leading-6 mt-1 mb-4 max-md:max-w-full">
                Ex CFO Talent Edge
              </div>
            </div>}
            {activeTestimonialContent === 2 && <div className="justify-center items-center bg-blue-950 flex w-full flex-col  px-20 py-12 max-md:max-w-full max-md:mt-10 max-md:px-5">
              <div className="text-white text-center text-2xl font-medium leading-[40px] tracking-tighter w-[1216px] mt-5 max-md:max-w-full max-md:text-4xl max-md:leading-[56px]">
              Working with CapItAll  team was a critical element for our due diligence to be completed diligently and on time, their team worked truly as our team, aligning with our needs, supporting our team clarified and bridging the gap between all parties involved. Due diligence is a critical phase in any transaction and in our case, working with Sharad and team made it seamless and so much more effective & efficient
              </div>
              {/*<img
                loading="lazy"
                src={ladyicon}
                srcSet={`${ladyicon} 1x, ${ladyicon} 2x , ${ladyicon} 4x, ${ladyicon} 8x, ${ladyicon} 12x, ${ladyicon} 16x, ${ladyicon} 20x`}
                alt='lady icon'
                className="aspect-square object-contain object-center w-16 justify-center items-center overflow-hidden max-w-full mt-8"
        />*/}
              <div className="text-white text-center text-lg font-medium leading-7 mt-4 max-md:max-w-full">
                Ester Martinez
              </div>
              <div className="text-white text-center text-base leading-6 mt-1 mb-4 max-md:max-w-full">
                CEO People Matters
              </div>
            </div>}
          </div>
          <div className="items-stretch self-center flex w-[83px] max-w-full gap-1.5 mt-5 mb-24 px-5">
            <div className="bg-orange-400 flex shrink-0 h-2.5 flex-col rounded-[31px]" />
            <div className={` cursor-pointer flex w-2.5 shrink-0 h-2.5 flex-col rounded-[31px] ${activeTestimonialContent === 1 ? 'bg-orange-400' : 'bg-zinc-300'
              }`} onClick={() => handleCircleClick(1)} />
            <div className={` cursor-pointer flex w-2.5 shrink-0 h-2.5 flex-col rounded-[31px] ${activeTestimonialContent === 2 ? 'bg-orange-400' : 'bg-zinc-300'}`} onClick={() => handleCircleClick(2)} />
          </div>

        </div>

      </div>
    </Layout>
  );
};

export default Home;