/**
 * File: Vdr.tsx
 * Description: This file contains the implementation of the Product component.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import Layout from '../component/layout';

//Image imports
import producttopimg from '../assets/images/compliance.jpg';
import tickicon from '../assets/images/product/tick-icon.svg';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../component/scrollAnimation';

const FixedAsset: React.FC = () => {
    // Component implementation goes here
    return (
        <Layout>
            <div className="bg-white flex flex-col">
                <div className="flex items-stretch gap-1.5 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-0">
                        <div className="items-stretch flex justify-between gap-2 pr-2">
                            <div className="text-orange-400 text-sm font-medium leading-5">
                                <Link to='/'> Home</Link>
                            </div>
                            <div className="text-orange-400 text-sm leading-5">&gt;</div>
                        </div>
                        <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                            <Link to='/product'>  Products </Link>
                        </div>
                    </div>
                    <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Fixed Asset Management Tool
                    </div>
                </div>
                <div className="self-center w-full max-w-[1597px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col mt-8 items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-black text-3xl font-semibold self-stretch max-md:max-w-full">
                                    Fixed Asset Management Tool
                                    <br />
                                    <br />
                                </div>
                                <div className="text-zinc-500 text-justify text-lg self-stretch mt-8 max-md:max-w-full">
                                Introducing Our Fixed Asset Management Tool<br /><br />

                                Do you have a hard time keeping track of your fixed assets? Are you worried about losing track of their value or location? If so, then you need our fixed asset management tool!<br /><br />

                                Our tool is a comprehensive solution for tracking all of your fixed assets. It allows you to easily create and manage asset records, track asset depreciation, and generate reports on asset usage and value. 
                                </div>
                                <div className="text-blue-950 text-xl mt-10 font-semibold self-stretch max-md:max-w-full">
                                    With our tool you can:
                                    <br />
                                </div>
                                <div className="text-zinc-500 text-justify text-lg self-stretch mt-8 max-md:max-w-full">
                                <ul className='list-disc'>
                                    <li>Create and manage asset records with ease</li>
                                    <li>Track asset depreciation over time</li>
                                    <li>Generate reports on asset usage and value</li>
                                    <li>Receive alerts when assets are nearing their end of life</li>
                                    <li>Easily search for assets by name, location, or serial number</li>
                                    <li>Export asset data to Excel or CSV files</li>
                                    <li>Our fixed asset management tool is the perfect solution for businesses of all sizes. It is easy to use, affordable, and highly scalable. </li>
                                </ul>
                                </div>
                                {/*<img
                                    loading="lazy"
                                    src={chaticon}
                                    alt='chat-icon'
                                    className="aspect-square object-contain object-center w-[101px] shadow-lg overflow-hidden max-w-full mr-10 mt-24 max-md:mr-2.5 max-md:mt-10"
                                />*/}
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[56%] max-md:w-full ml-10 max-md:ml-0">
                            <img
                                loading="lazy"
                                src={producttopimg}
                                srcSet={`${producttopimg} 1x, ${producttopimg} 2x , ${producttopimg} 4x, ${producttopimg} 8x, ${producttopimg} 12x, ${producttopimg} 16x, ${producttopimg} 20x`}
                                alt='product-top-img'
                                className="aspect-[1.69] object-contain object-center w-full overflow-hidden max-w-[707px]"
                            />
                        </div>
                    </div>
                </div>
                <ScrollAnimation>
                <div className="self-center flex w-full max-w-[1527px] flex-col mt-24 px-5 max-md:max-w-full max-md:mt-10">
                    <div className="text-blue-950 text-3xl font-semibold self-start max-md:max-w-full m-auto">
                        Benefits of using A Fixed Asset Management Tool
                    </div>
                    <div className="text-zinc-500 text-center text-xl mt-4 self-start max-md:max-w-full m-auto">
                        There are many benefits to using our fixed asset management tool. Here are just a few:
                    </div>
                    <div className="self-stretch mt-24 max-md:max-w-full max-md:mt-10 mb-20">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col items-stretch w-[33%] max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-5 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Improved asset tracking:{" "}
                                        <span className=" leading-6">
                                        Our tool makes it easy to track all of your fixed assets, from their purchase date to their current location. This helps you to ensure that your assets are always accounted for and that you are not losing money on depreciation. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-8 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Increased asset visibility:{" "}
                                        <span className=" leading-6">
                                        Our tool provides you with a clear overview of all of your fixed assets. This helps you to make informed decisions about asset allocation and maintenance. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-2.5 w-full px-8 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-8 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.04] object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto mt-1">
                                        Reduced asset costs:{" "}
                                        <span className=" leading-6">
                                        Our tool can help you to reduce your asset costs by identifying assets that are nearing their end of life and by tracking asset usage. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[1007px] max-w-full self-start max-md:mt-10 m-auto">
                        <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                            <div className="flex flex-col w-6/12 items-stretch max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-7 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-7 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Improved compliance:{" "}
                                        <span className=" leading-6">
                                        Our tool can help you to comply with asset-related regulations, such as Sarbanes-Oxley and ISO 55000. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-6/12 items-stretch max-md:w-full max-md:ml-0">
                                <div className="border border-[color:var(--gray-200,#E4E4E7)] bg-white flex grow justify-between gap-3 w-full pl-8 pr-7 py-10 rounded-3xl border-solid items-start max-md:max-w-full max-md:flex-wrap max-md:mt-7 max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src={tickicon}
                                        alt='tick-icon'
                                        className="aspect-[1.09] object-contain object-center w-[25px] overflow-hidden shrink-0 max-w-full"
                                    />
                                    <div className="justify-center text-zinc-900 text-base font-semibold leading-7 tracking-normal grow shrink basis-auto">
                                        Simplified reporting:{" "}
                                        <span className=" leading-6">
                                        Our tool generates reports that can help you to track asset usage, depreciation, and value. This information can be used to make informed decisions about your asset management strategy. 
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-zinc-500 text-left text-xl mt-14 self-start max-md:max-w-full m-auto">
                    If you are looking for a comprehensive and easy-to-use fixed asset management tool, then our tool is the perfect solution for you. Contact us today to learn more about how our tool can help you to improve your asset management processes. 
                    </div>
                    
                </div>
                </ScrollAnimation>
                <div className="bg-blue-950 self-stretch flex w-full flex-col items-center mt-2 px-20 py-12 max-md:max-w-full max-md:px-5">
                    <div className="text-white text-center text-4xl font-semibold leading-5 mt-6 max-md:max-w-full m-text-lh">
                        Overview about Fixed Asset Management Tool
                    </div>
                    <Link to="/contact">
                    <div className="text-white text-center text-base font-semibold leading-5 whitespace-nowrap justify-center items-center rounded bg-orange-400 w-[237px] max-w-full mt-11 mb-1.5 px-16 py-4 max-md:mt-10 max-md:px-5">
                        Get Quotation
                    </div>
                    </Link>
                </div>
               

            </div>
        </Layout>
    );
};

export default FixedAsset;
