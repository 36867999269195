/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const VuesaxLinearCloudConnection1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`vuesax-linear-cloud-connection-1 ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.43159 11.095C2.67159 11.4333 2.68326 18.3283 7.43159 18.6666H18.7016C20.0666 18.6783 21.3849 18.165 22.3999 17.2433C25.7366 14.3267 23.9516 8.49329 19.5533 7.93329C17.9783 -1.56338 4.22327 2.04164 7.47827 11.095"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M14 18.6666V22.1666"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M14.0001 26.8333C15.2887 26.8333 16.3334 25.7886 16.3334 24.5C16.3334 23.2113 15.2887 22.1666 14.0001 22.1666C12.7114 22.1666 11.6667 23.2113 11.6667 24.5C11.6667 25.7886 12.7114 26.8333 14.0001 26.8333Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M20.9999 24.5H16.3333"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M11.6667 24.5H7"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};
