/**
 * File: Vdr.tsx
 * Description: This file contains the implementation of the Product component.
 * Author: Capitall
 * Date: 2023-12-29
 * Company: Capitall.Io
 */

import React from 'react';
import Layout from '../component/layout';

//Image imports
import producttopimg from '../assets/images/compliance.jpg';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../component/scrollAnimation';

const VendorManagement: React.FC = () => {
    // Component implementation goes here
    return (
        <Layout>
            <div className="bg-white flex flex-col">
                <div className="flex items-stretch gap-1.5 ml-24 mt-7 self-start max-md:ml-2.5">
                    <div className="items-stretch flex justify-between gap-0">
                        <div className="items-stretch flex justify-between gap-2 pr-2">
                            <div className="text-orange-400 text-sm font-medium leading-5">
                                <Link to='/'> Home</Link>
                            </div>
                            <div className="text-orange-400 text-sm leading-5">&gt;</div>
                        </div>
                        <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                            <Link to='/product'>  Products </Link>
                        </div>
                    </div>
                    <div className="text-orange-400 text-sm leading-5">&gt;</div>
                    <div className="text-orange-400 text-sm font-medium leading-5 grow whitespace-nowrap">
                        Vendor Management tool
                    </div>
                </div>
                <div className="self-center w-full max-w-[1597px] mt-16 max-md:max-w-full max-md:mt-10">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[44%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col mt-8 items-end max-md:max-w-full max-md:mt-10">
                                <div className="text-black text-3xl font-semibold self-stretch max-md:max-w-full">
                                    Vendor Management tool
                                    <br />
                                    <br />
                                </div>
                                <div className="text-blue-950 text-xl mt-10 font-semibold self-stretch max-md:max-w-full">
                                    Introducing Our Vendor Management and PO Tool 
                                    <br />
                                </div>
                                <div className="text-zinc-500 text-justify text-lg self-stretch mt-8 max-md:max-w-full">
                                We are excited to announce the launch of our new vendor management tool and PO tool! These two tools are designed to help businesses of all sizes streamline their vendor management processes and improve their procurement efficiency. 
                                </div>
                                {/*<img
                                    loading="lazy"
                                    src={chaticon}
                                    alt='chat-icon'
                                    className="aspect-square object-contain object-center w-[101px] shadow-lg overflow-hidden max-w-full mr-10 mt-24 max-md:mr-2.5 max-md:mt-10"
                                />*/}
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[56%] max-md:w-full ml-10 max-md:ml-0">
                            <img
                                loading="lazy"
                                src={producttopimg}
                                srcSet={`${producttopimg} 1x, ${producttopimg} 2x , ${producttopimg} 4x, ${producttopimg} 8x, ${producttopimg} 12x, ${producttopimg} 16x, ${producttopimg} 20x`}
                                alt='product-top-img'
                                className="aspect-[1.69] object-contain object-center w-full overflow-hidden max-w-[707px]"
                            />
                        </div>
                    </div>
                </div>
                <ScrollAnimation>
                <div className="self-center flex w-full max-w-[1597px] flex-col px-5 max-md:max-w-full max-md:mt-10">
                    <div className="text-blue-950 text-2xl font-semibold self-start max-md:max-w-full">
                        Our Vendor Management Tool:
                    </div>
                    <div className="text-zinc-500 text-left text-xl mt-4 self-start max-md:max-w-full">
                    Our vendor management tool provides a central repository for all of your vendor information. 
                    This includes contact information, contract terms, purchase history, and performance ratings. 
                    You can easily search and filter your vendor data, and the tool also generates reports that can help you track your vendor spend and identify areas for improvement.
                    <br /><br />
                    Our PO tool makes it easy to create and manage purchase orders. You can easily create POs from templates, 
                    and the tool will automatically populate the required information. The tool also tracks the status of your POs and sends notifications when they are due. 
                    </div>
                    
                </div>
                </ScrollAnimation>
                <ScrollAnimation>
                <div className="self-center flex w-full max-w-[1597px] flex-col mt-14 mb-10 px-5 max-md:max-w-full max-md:mt-10">
                    <div className="text-blue-950 text-2xl font-semibold self-start max-md:max-w-full">
                    How Can These Tools Help You?
                    </div>
                    <div className="text-zinc-500 text-left text-xl mt-4 self-start max-md:max-w-full">
                    <ul className='list-disc'>
                        <li>Save time and money by automating your vendor management processes </li>
                        <li>Improve your procurement efficiency by getting better insights into your vendor spend</li>
                        <li>Reduce the risk of vendor fraud and errors </li>
                        <li>Improve your supplier relationships by providing better communication and transparency</li>
                    </ul>
                    </div>
                    
                </div>
                </ScrollAnimation>
                <div className="bg-blue-950 self-stretch flex w-full flex-col items-center mt-2 px-20 py-12 max-md:max-w-full max-md:px-5">
                    <div className="text-white text-center text-4xl font-semibold leading-5 mt-6 max-md:max-w-full m-text-lh">
                        Overview about Vendor Management Tool
                    </div>
                    <Link to="/contact">
                    <div className="text-white text-center text-base font-semibold leading-5 whitespace-nowrap justify-center items-center rounded bg-orange-400 w-[237px] max-w-full mt-11 mb-1.5 px-16 py-4 max-md:mt-10 max-md:px-5">
                        Get Quotation
                    </div>
                    </Link>
                </div>
               

            </div>
        </Layout>
    );
};

export default VendorManagement;
